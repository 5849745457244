import React, { useState } from 'react';
import { 
  Box, Button, LinearProgress, Typography, Snackbar, Alert 
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 Mo en octets
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

function ImageUpload({ onImageUploaded }) {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError("L'image ne doit pas dépasser 5 Mo.");
        return;
      }
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setError("Seuls les fichiers JPEG, PNG et GIF sont acceptés.");
        return;
      }
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setError(null);
    }
  };

  const handleUpload = async () => {
    if (!imageFile) return;

    setUploading(true);
    const storageRef = ref(storage, `eventImages/${Date.now()}_${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading image:", error);
        setError("Erreur lors de l'upload de l'image. Veuillez réessayer.");
        setUploading(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          onImageUploaded(downloadURL);
          setUploading(false);
          setImageFile(null);
          setImagePreview(null);
        } catch (error) {
          console.error("Error getting download URL:", error);
          setError("Erreur lors de la récupération de l'URL de l'image. Veuillez réessayer.");
          setUploading(false);
        }
      }
    );
  };

  return (
    <Box>
      <input
        accept={ALLOWED_FILE_TYPES.join(',')}
        style={{ display: 'none' }}
        id="image-upload"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload">
        <Button
          variant="outlined"
          component="span"
          startIcon={<AddPhotoAlternateIcon />}
          fullWidth
          disabled={uploading}
        >
          {uploading ? 'Uploading...' : 'Upload Event Image (JPEG, PNG, GIF - Max 5 MB)'}
        </Button>
      </label>
      {imagePreview && (
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <img src={imagePreview} alt="Event preview" style={{ maxWidth: '100%', maxHeight: 200, borderRadius: '8px' }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={uploading}
            style={{ marginTop: '10px' }}
          >
            Confirm Upload
          </Button>
        </Box>
      )}
      {uploading && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" color="textSecondary">{`${Math.round(uploadProgress)}%`}</Typography>
        </Box>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ImageUpload;