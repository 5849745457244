import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Container, Paper, TextField, Button, Typography, Avatar,
  Snackbar, Alert, Box, CircularProgress, IconButton,
  Chip, Switch, FormControlLabel, Divider, Tabs, Tab,
  Tooltip, Badge, InputAdornment, Grid, List, ListItem,
  ListItemIcon, ListItemText, Checkbox, Dialog, DialogTitle,
  DialogContent, DialogActions, ListItemSecondaryAction, useTheme, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SecurityIcon from '@mui/icons-material/Security';
import PersonIcon from '@mui/icons-material/Person';
import BlockIcon from '@mui/icons-material/Block';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VerifiedIcon from '@mui/icons-material/Verified';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { requestNotificationPermission } from '../services/notificationService';
import PWAInstallPrompt from '../components/PWAInstallPrompt';
import { categories } from '../constants/categories';
import { auth } from '../firebase';
import { 
  fetchPreferredCategories, 
  updatePreferredCategories, 
  getLocalPreferredCategories,
  toggleLocalPreferredCategory
} from '../utils/categoryUtils';
import { api } from '../utils/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginBottom: theme.spacing(2),
}));

const CharacterCountInput = ({ value, onChange, maxLength, label, multiline = false, rows = 1, helperText, error, ...props }) => {
  const safeValue = value ?? '';

  return (
    <TextField
      fullWidth
      label={label}
      value={safeValue}
      onChange={onChange}
      multiline={multiline}
      rows={rows}
      error={error}
      helperText={
        <>
          {helperText}
          <Typography variant="caption" style={{ float: 'right' }}>
            {safeValue.length}/{maxLength}
          </Typography>
        </>
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={`Maximum ${maxLength} characters`}>
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

const StyledBadge = styled(Chip)(({ theme, badgeType }) => {
  const badgeStyles = {
    default: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    achievement: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    special: {
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      color: 'white',
    },
    rare: {
      background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
      color: 'white',
    },
    legendary: {
      background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
      color: 'black',
    },
  };

  return {
    ...badgeStyles[badgeType || 'default'],
    margin: theme.spacing(0.5),
    fontWeight: 'bold',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
    },
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  };
});

const BadgeIcon = styled(EmojiEventsIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const BadgeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 220,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

const getBadgeType = (badgeName) => {
  if (badgeName.includes('Legendary')) return 'legendary';
  if (badgeName.includes('Rare')) return 'rare';
  if (badgeName.includes('Special')) return 'special';
  if (badgeName.includes('Achievement')) return 'achievement';
  return 'default';
};

function Profile() {
  const [profile, setProfile] = useState({
    displayName: '',
    bio: '',
    photoURL: '',
    email: '',
    username: '',
    isCertified: false,
    certificationType: 'none',
    certifiedUntil: null,
    badges: [],
    preferredCategories: [],
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [photoFile, setPhotoFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [notificationSettings, setNotificationSettings] = useState({
    privateEventNotifications: true,
    friendEventNotifications: true,
    friendRequestNotifications: true,
    publicEventNotifications: false,
    emailNotifications: true,
  });
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [preferredCategories, setPreferredCategories] = useState([]);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [openBlockedDialog, setOpenBlockedDialog] = useState(false);

  const navigate = useNavigate();
  const { currentUser, getIdToken } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchBlockedUsers = useCallback(async () => {
    if (!currentUser) return;
    try {
      const token = await getIdToken();
      const response = await api.get('/users/blocked', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setBlockedUsers(response.data.blockedUsers);
    } catch (error) {
      console.error('Error fetching blocked users:', error);
      setSnackbar({ open: true, message: 'Error fetching blocked users', severity: 'error' });
    }
  }, [currentUser, getIdToken]);

  const handleUnblock = async (userId) => {
    try {
      const token = await getIdToken();
      await api.post(`/users/unblock/${userId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      await fetchBlockedUsers();
      setSnackbar({ open: true, message: 'User unblocked successfully', severity: 'success' });
    } catch (error) {
      console.error('Error unblocking user:', error);
      setSnackbar({ open: true, message: 'Error unblocking user', severity: 'error' });
    }
  };

  const fetchProfile = useCallback(async () => {
    if (!currentUser) {
      setLoading(false);
      return;
    }
    
    try {
      const token = await getIdToken();
      const response = await api.get('/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.data.email !== currentUser.email) {
        throw new Error('Profile data mismatch');
      }
      
      setProfile({
        ...response.data,
        preferredCategories: response.data.preferredCategories || [],
      });
      setNotificationSettings(response.data.notificationSettings || {
        privateEventNotifications: true,
        friendEventNotifications: true,
        friendRequestNotifications: true,
        publicEventNotifications: false,
        emailNotifications: true,
      });
    } catch (error) {
      console.error("Error fetching profile:", error);
      setSnackbar({ open: true, message: 'Error fetching profile. Please try again.', severity: 'error' });
      setProfile({
        displayName: currentUser.displayName || '',
        email: currentUser.email || '',
        photoURL: currentUser.photoURL || '',
        username: '',
        bio: '',
        isCertified: false,
        certificationType: 'none',
        certifiedUntil: null,
        badges: [],
        preferredCategories: [],
      });
    } finally {
      setLoading(false);
    }
  }, [currentUser, getIdToken]);

  useEffect(() => {
    fetchProfile();
    fetchBlockedUsers();
  }, [fetchProfile, fetchBlockedUsers]);

  useEffect(() => {
    const localCategories = getLocalPreferredCategories();
    setPreferredCategories(localCategories);
    loadCategories();
  }, []);

  const loadCategories = useCallback(async () => {
    try {
      const token = await getIdToken();
      const categories = await fetchPreferredCategories(token);
      setPreferredCategories(categories);
    } catch (error) {
      console.error("Error loading categories:", error);
      setSnackbar({ open: true, message: 'Error fetching preferred categories', severity: 'error' });
    }
  }, [getIdToken]);

  const handleBackClick = () => {
    navigate('/');
  };

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'displayName':
        if (value.length < 2 || value.length > 50) {
          error = 'Display name must be between 2 and 50 characters';
        }
        break;
      case 'username':
        if (!/^[a-zA-Z0-9_]{3,20}$/.test(value)) {
          error = 'Username must be 3-20 characters and can only contain letters, numbers, and underscores';
        }
        break;
      case 'bio':
        if (value.length > 500) {
          error = 'Bio must not exceed 500 characters';
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
    const error = validateField(name, value);
    setErrors({ ...errors, [name]: error });
    if (name === 'username') {
      checkUsernameAvailability(value);
    }
  };

  const checkUsernameAvailability = useCallback(async (username) => {
    if (!username || username === profile.username) {
      setErrors(prev => ({ ...prev, username: '' }));
      return;
    }

    const error = validateField('username', username);
    if (error) {
      setErrors(prev => ({ ...prev, username: error }));
      return;
    }

    try {
      const token = await getIdToken();
      const response = await api.get(`/profile/check-username/${username}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (!response.data.available) {
        setErrors(prev => ({ ...prev, username: 'This username is already taken' }));
      } else {
        setErrors(prev => ({ ...prev, username: '' }));
      }
    } catch (error) {
      console.error("Error checking username availability:", error);
      setErrors(prev => ({ ...prev, username: 'Error checking username availability' }));
    }
  }, [getIdToken, profile.username]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = Object.keys(profile).reduce((acc, key) => {
      const error = validateField(key, profile[key]);
      if (error) acc[key] = error;
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setSnackbar({ open: true, message: 'Please correct the errors before submitting', severity: 'error' });
      return;
    }

    setSaving(true);
    try {
      const token = await getIdToken();
      const formData = new FormData();
      Object.keys(profile).forEach(key => formData.append(key, profile[key]));
      if (photoFile) {
        formData.append('photo', photoFile);
      }

      const response = await api.put('/profile', formData, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      setProfile(prevProfile => ({ ...prevProfile, ...response.data }));
      setPhotoFile(null);
      setPreviewURL('');
      setSnackbar({ open: true, message: 'Profile updated successfully!', severity: 'success' });
    } catch (error) {
      console.error("Error updating profile: ", error);
      setSnackbar({ open: true, message: 'Error updating profile. Please try again.', severity: 'error' });
    }
    setSaving(false);
  };

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      setPhotoFile(e.target.files[0]);
      setPreviewURL(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setProfile({
        displayName: '',
        bio: '',
        photoURL: '',
        email: '',
        username: '',
        isCertified: false,
        certificationType: 'none',
        certifiedUntil: null,
        badges: [],
        preferredCategories: [],
      });
      setPreferredCategories([]);
      setNotificationSettings({
        privateEventNotifications: true,
        friendEventNotifications: true,
        friendRequestNotifications: true,
        publicEventNotifications: false,
        emailNotifications: true,
      });
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
      setSnackbar({ open: true, message: 'Error signing out. Please try again.', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleToggleNotification = (setting) => async (event) => {
    const newSettings = { ...notificationSettings, [setting]: event.target.checked };
    setNotificationSettings(newSettings);
    try {
      const token = await getIdToken();
      await api.put('/profile/notifications', { notificationSettings: newSettings }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Notification settings updated successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to update notification settings', severity: 'error' });
    }
  };

  const handleEnableNotifications = async () => {
    const result = await requestNotificationPermission(currentUser.uid);
    if (result) {
      setSnackbar({ open: true, message: 'Notifications enabled successfully', severity: 'success' });
    } else {
      setSnackbar({ open: true, message: 'Failed to enable notifications', severity: 'error' });
    }
  };

  const formatCertificationDate = (timestamp) => {
    if (!timestamp) return 'Invalid Date';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString();
  };

  const handleOpenCategoryDialog = async () => {
    await loadCategories();
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };

  const handleCategoryToggle = async (category) => {
    const updatedCategories = toggleLocalPreferredCategory(category);
    setPreferredCategories(updatedCategories);
    
    try {
      const token = await getIdToken();
      await updatePreferredCategories(token, updatedCategories);
      setSnackbar({ open: true, message: 'Preferred categories updated successfully', severity: 'success' });
    } catch (error) {
      console.error("Error updating categories:", error);
      setSnackbar({ open: true, message: 'Failed to update preferred categories', severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!currentUser) {
    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom>
            You are not logged in.
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
            Go to Login
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="md">
      <StyledPaper elevation={6}>
        <BackButton color="primary" onClick={handleBackClick}>
          <ArrowBackIcon />
        </BackButton>
        <IconButton
          color="primary" 
          aria-label="logout" 
          onClick={handleLogout} 
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <LogoutIcon />
        </IconButton>
        
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={profile.isCertified && <VerifiedIcon color="primary" />}
        >
          <StyledAvatar src={profile.photoURL || profile.photoURL} />
        </Badge>
        <label htmlFor="icon-button-file">
          <input
            accept="image/*"
            id="icon-button-file"
            type="file"
            onChange={handlePhotoChange}
            style={{ display: 'none'}}
          />
          <IconButton color="primary" aria-label="upload picture" component="span">
            <CameraAltIcon />
          </IconButton>
        </label>

        <Typography component="h1" variant="h5" gutterBottom>
          Your Profile
        </Typography>

        <Box mt={2} mb={2}>
          <Typography variant="h6" gutterBottom>Badges</Typography>
          <Grid container spacing={1} justifyContent="center">
            {profile.badges.map((badge, index) => (
              <Grid item key={index}>
                <BadgeTooltip title={`Description of ${badge} badge`} placement="top">
                  <StyledBadge
                    icon={<BadgeIcon />}
                    label={badge}
                    badgeType={getBadgeType(badge)}
                  />
                </BadgeTooltip>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Typography variant="subtitle1" gutterBottom>
          {profile.isCertified 
            ? `Certified ${profile.certificationType === 'personal' ? 'User' : 'Organization'}`
            : "Not certified"}
        </Typography>
        {profile.isCertified && profile.certifiedUntil && (
          <Typography variant="body2" gutterBottom>
            Certified until: {formatCertificationDate(profile.certifiedUntil)}
          </Typography>
        )}

        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          centered 
          sx={{ mb: 2 }}
          variant={isMobile ? "scrollable" : "standard"}
          scrollButtons="auto"
        >
          <Tab icon={<PersonIcon />} label="Profile" />
          <Tab icon={<NotificationsIcon />} label="Notifications" />
          <Tab icon={<SecurityIcon />} label="Security" />
          <Tab icon={<BlockIcon />} label="Blocked Users" />
        </Tabs>

        {tabValue === 0 && (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={profile.email}
              InputProps={{
                readOnly: true,
              }}
            />
            <CharacterCountInput
              required
              name="displayName"
              label="Display Name"
              value={profile.displayName ?? ''}
              onChange={handleChange}
              maxLength={50}
              error={!!errors.displayName}
              helperText={errors.displayName}
            />
            <CharacterCountInput
              required
              name="username"
              label="Username"
              value={profile.username}
              onChange={handleChange}
              maxLength={20}
              error={!!errors.username}
              helperText={errors.username}
            />
            <CharacterCountInput
              name="bio"
              label="Bio"
              value={profile.bio}
              onChange={handleChange}
              maxLength={500}
              multiline
              rows={4}
              error={!!errors.bio}
              helperText={errors.bio}
            />
            
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenCategoryDialog}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              Manage Preferred Categories
            </Button>
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={saving || Object.keys(errors).some(key => !!errors[key])}
            >
              {saving ? 'Saving...' : 'Save Profile'}
            </Button>
          </form>
        )}

        {tabValue === 1 && (
          <Box width="100%">
            <Typography variant="h6" gutterBottom>Notification Settings</Typography>
            <FormControlLabel
              control={<Switch checked={notificationSettings.privateEventNotifications} onChange={handleToggleNotification('privateEventNotifications')} />}
              label="Private Event Invitations"
            />
            <FormControlLabel
              control={<Switch checked={notificationSettings.friendEventNotifications} onChange={handleToggleNotification('friendEventNotifications')} />}
              label="Friend Event Invitations"
            />
            <FormControlLabel
              control={<Switch checked={notificationSettings.friendRequestNotifications} onChange={handleToggleNotification('friendRequestNotifications')} />}
              label="Friend Requests"
            />
            <FormControlLabel
              control={<Switch checked={notificationSettings.publicEventNotifications} onChange={handleToggleNotification('publicEventNotifications')} />}
              label="Public Events Near You"
            />
            <FormControlLabel
              control={<Switch checked={notificationSettings.emailNotifications} onChange={handleToggleNotification('emailNotifications')} />}
              label="Email Notifications"
            />
            <Divider sx={{ my: 2 }} />
            <Button variant="contained" color="primary" onClick={handleEnableNotifications}>
              Enable Push Notifications
            </Button>
          </Box>
        )}

        {tabValue === 2 && (
          <Box width="100%">
            <Typography variant="h6" gutterBottom>Security Settings</Typography>
            <Button variant="outlined" color="primary" fullWidth sx={{ mb: 2 }}>
              Change Password
            </Button>
            <Button variant="outlined" color="primary" fullWidth sx={{ mb: 2 }}>
              Enable Two-Factor Authentication
            </Button>
            <Button variant="outlined" color="error" fullWidth>
              Delete Account
            </Button>
          </Box>
        )}

        {tabValue === 3 && (
          <Box width="100%">
            <Typography variant="h6" gutterBottom>Blocked Users</Typography>
            {blockedUsers.length === 0 ? (
              <Typography>You haven't blocked any users.</Typography>
            ) : (
              <List>
                {blockedUsers.map((userId) => (
                  <ListItem key={userId}>
                    <ListItemText primary={userId} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="unblock" onClick={() => handleUnblock(userId)}>
                        <PersonAddIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        )}
      </StyledPaper>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
        <DialogTitle>Manage Preferred Categories</DialogTitle>
        <DialogContent>
          <List>
            {categories.map((category) => (
              <ListItem key={category.value} dense button onClick={() => handleCategoryToggle(category.value)}>
                <ListItemIcon>
                  <category.icon />
                </ListItemIcon>
                <ListItemText primary={category.label} />
                <Checkbox
                  edge="end"
                  checked={preferredCategories.includes(category.value)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCategoryDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <PWAInstallPrompt />
    </Container>
  );
}

export default Profile;