import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { auth, storage } from '../firebase';
import {
  Container,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
  Box,
  Paper,
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Divider,
  Fade,
  LinearProgress,
  Link,
  Snackbar,
  Alert,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  StepButton,
  Grid

} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import PlaceIcon from '@mui/icons-material/Place';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import HashtagInput from '../components/HashtagInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TermsOfService from './TermsOfService';
import 'leaflet/dist/leaflet.css';
import debounce from 'lodash/debounce';
import CustomMapContainer from '../components/CustomMapContainer';
import { useMap } from 'react-leaflet';
import ImageUpload from '../components/ImageUpload';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../utils/api';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { 
  DatePicker,
  TimePicker 
} from '@mui/x-date-pickers';
import {
  addHours,
  differenceInMinutes,
  getHours,
  getMinutes,
  setHours,
  setMinutes, addYears, addMinutes, addDays, setSeconds
} from 'date-fns';
import Switch from '@mui/material/Switch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';





const CharacterCountInput = ({ value, onChange, maxLength, label, multiline = false, rows = 1, icon, ...props }) => {
  const characterCount = value.length;
  const progress = (characterCount / maxLength) * 100;

  return (
    <Box mb={2}>
      <TextField
        variant="outlined"
        fullWidth
        value={value}
        onChange={(e) => {
          if (e.target.value.length <= maxLength) {
            onChange(e);
          }
        }}
        label={label}
        multiline={multiline}
        rows={rows}
        InputProps={{
          startAdornment: icon,
        }}
        {...props}
      />
      <Box display="flex" alignItems="center" mt={1}>
        <Box width="100%" mr={1}>
          <LinearProgress 
            variant="determinate" 
            value={progress}
            color={progress > 90 ? "error" : "primary"}
          />
        </Box>
        <Box minWidth={60}>
          <Typography variant="body2" color="textSecondary">
            {characterCount}/{maxLength}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const StepDot = styled('div')(({ theme, active, completed }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  margin: '0 4px',
  background: active || completed ? theme.palette.primary.main : theme.palette.grey[400],
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));



const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: 'rgba(50, 50, 50, 0.5)',
  backdropFilter: 'blur(5px)',
  borderRadius: theme.spacing(1),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));


const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: theme.palette.primary.main,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function CreateEvent() {

  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [eventType, setEventType] = useState('');
  const [eventCategory, setEventCategory] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [friends, setFriends] = useState([]);
  const [groups, setGroups] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [openTerms, setOpenTerms] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [hashtags, setHashtags] = useState([]);
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [startDate, setStartDate] = useState(addMinutes(new Date(), 5));
  const [username, setUsername] = useState('');
  const { currentUser, getIdToken } = useAuth();
  const [errors, setErrors] = useState({});
  const [venueName, setVenueName] = useState('');
  const [price, setPrice] = useState('');




  const steps = ['Event Type', 'Event Details', 'Date & Location', 'Image & Hashtags', 'Review & Create'];
  const [activeStep, setActiveStep] = useState(0);


  const eventCategories = [
    { value: 'culture', label: 'Culture and Entertainment' },
    { value: 'sports', label: 'Sports and Physical Activities' },
    { value: 'family', label: 'Family and Children' },
    { value: 'nightlife', label: 'Nightlife and Outings' },
    { value: 'professional', label: 'Professional Events and Networking' },
    { value: 'markets', label: 'Markets and Fairs' },
    { value: 'social', label: 'Social and Community Events' },
    { value: 'education', label: 'Education and Personal Development' },
    { value: 'tech', label: 'Tech and Innovation' },
    { value: 'wellness', label: 'Wellness and Health' },
    { value: 'other', label: 'Other' }
  ];

  const handleBackClick = () => {
    navigate('/');
  };
  const BackButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  }));

  const handleHashtagsChange = (newHashtags) => {
    setHashtags(newHashtags);
  };

 
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    
    if (hours === 0) {
      return `${remainingMinutes} minutes`;
    } else if (minutes % 60 === 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes} minutes`;
    }
  };


    // Fonction pour récupérer le profil de l'utilisateur
  const fetchUserProfile = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsername(response.data.username);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setSnackbar({ open: true, message: 'Error fetching user profile', severity: 'error' });
    }
  }, [getIdToken]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);


  // Fonction pour récupérer les amis
  const fetchFriends = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdToken();
        const response = await api.get('/friends', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setFriends(response.data);
      } catch (error) {
        console.error('Error fetching friends:', error);
        setSnackbar({ open: true, message: 'Error fetching friends', severity: 'error' });
      }
    }
  }, []);

  // Fonction pour récupérer les groupes
  const fetchGroups = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const token = await user.getIdToken();
        const response = await api.get('/friends/groups', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setGroups(response.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
        setSnackbar({ open: true, message: 'Error fetching groups', severity: 'error' });
      }
    }
  }, []);

  useEffect(() => {
    if (eventType === 'private') {
      fetchFriends();
      fetchGroups();
    }
  }, [eventType, fetchFriends, fetchGroups]);

  const handleNext = () => {
    const isValid = isStepValid();
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      let errorMessage = '';
      switch (activeStep) {
        case 0:
          errorMessage = 'Please select an event type.';
          break;
        case 1:
          if (title.trim().length < 3) {
            errorMessage = 'Title must be at least 3 characters long.';
          } else if (description.trim().length < 10) {
            errorMessage = 'Description must be at least 10 characters long.';
          } else if (description.trim().length > 1000) {
            errorMessage = 'Description must not exceed 1000 characters.';
          } else if (eventType === 'public' && eventCategory === '') {
            errorMessage = 'Please select an event category for public events.';
          } else {
            errorMessage = 'Please fill in all required fields correctly.';
          }
          break;
        case 2:
          if (!startDate) {
            errorMessage = 'Please select a start date and time.';
          } else if (address.trim().length < 5) {
            errorMessage = 'Address must be at least 5 characters long.';
          } else if (address.trim().length > 100) {
            errorMessage = 'Address must not exceed 100 characters.';
          } else if (!coordinates) {
            errorMessage = 'Please enter a valid address to get coordinates.';
          } else {
            errorMessage = 'Please fill in all location details correctly.';
          }
          break;
        case 3:
          if (image === null) {
            errorMessage = 'Please upload an event image.';
          } else if (hashtags.length > 3) {
            errorMessage = 'You can add a maximum of 3 hashtags.';
          } else {
            errorMessage = 'Please complete the image and hashtags section.';
          }
          break;
        case 4:
          errorMessage = 'Please accept the terms and conditions.';
          break;
        default:
          errorMessage = 'An unknown error occurred. Please check all fields.';
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepValid = () => {
    switch (activeStep) {
      case 0:
        return eventType !== '';
      case 1:
        return (
          title.trim().length >= 3 &&
          description.trim().length >= 10 &&
          description.trim().length <= 1000 &&
          (eventType !== 'public' || eventCategory !== '')
        );
      case 2:
        return (
          startDate &&
          address.trim().length >= 5 &&
          address.trim().length <= 100 &&
          coordinates
        );
      case 3:
        return image !== null && hashtags.length <= 3;
      case 4:
        return acceptedTerms;
      default:
        return false;
    }
  };


    


  const handleEventTypeChange = (event) => {
    const selectedEventType = event.target.value;
    setEventType(selectedEventType);
    
    if (selectedEventType === 'public' || selectedEventType === 'friends') {
      setInvitedUsers([]);
    }
  };

  const handleEventCategoryChange = (event) => {
    setEventCategory(event.target.value);
  };

  const handleInvite = (selected) => {
    let newInvitedUsers = [...invitedUsers];
    selected.forEach(item => {
      if (item.type === 'friend' && !newInvitedUsers.some(user => user.id === item.uid)) {
        newInvitedUsers.push({ id: item.uid, name: item.displayName, type: 'friend' });
      } else if (item.type === 'group') {
        item.members.forEach(memberId => {
          if (!newInvitedUsers.some(user => user.id === memberId)) {
            const friend = friends.find(f => f.uid === memberId);
            if (friend) {
              newInvitedUsers.push({ id: memberId, name: friend.displayName, type: 'friend' });
            }
          }
        });
      }
    });
    setInvitedUsers(newInvitedUsers);
    setOpenInviteDialog(false);
  };

  const removeInvitedUser = (userId) => {
    setInvitedUsers(invitedUsers.filter(user => user.id !== userId));
  };

  

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.log("No file selected");
      return;
    }
  
    setImagePreview(URL.createObjectURL(file));
    setImageUploading(true);
    setImageUploadProgress(0);
  
    const storageRef = ref(storage, `eventImages/${auth.currentUser.uid}/${Date.now()}_${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        setImageUploadProgress(progress);
      },
      (error) => {
        console.error("Error uploading image:", error);
        setSnackbar({ open: true, message: 'Failed to upload image. Please try again.', severity: 'error' });
        setImageUploading(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('File available at', downloadURL);
          setImage(downloadURL);
          setImageUploading(false);
        } catch (error) {
          console.error("Error getting download URL:", error);
          setSnackbar({ open: true, message: 'Failed to get image URL. Please try again.', severity: 'error' });
          setImageUploading(false);
        }
      }
    );
  };


  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    debouncedAddressLookup(newAddress);
  };

  function SetViewOnChange({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      setSnackbar({ open: true, message: 'Please accept the terms and conditions to create the event.', severity: 'warning' });
      return;
    }
    if (!coordinates) {
      setSnackbar({ open: true, message: 'Location data is incomplete. Please enter a valid address.', severity: 'error' });
      return;
    }
    setLoading(true);
  
    try {
      const user = auth.currentUser;
      let invitedUserIds = [user.uid];
  
      if (eventType === 'friends') {
        const friendIds = friends.map(friend => friend.id);
        invitedUserIds = [...new Set([...invitedUserIds, ...friendIds])];
      } else if (eventType === 'private') {
        const selectedUserIds = invitedUsers.map(user => user.id);
        invitedUserIds = [...new Set([...invitedUserIds, ...selectedUserIds])];
      }
  
      const eventData = {
        title,
        description,
        startDate: startDate.toISOString(),
        endDate: endDate ? endDate.toISOString() : null,
        address,
        coordinates,
        eventType,
        eventCategory: eventType === 'public' ? eventCategory : undefined,
        invitedUsers: eventType === 'public' ? undefined : invitedUserIds,
        imageUrl: image,
        hashtags,
        username,
        venueName,
        price
      };
  
      console.log('Submitting event data:', eventData);
  
      const token = await user.getIdToken();
      const response = await api.post('/events/create', eventData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      
      setSnackbar({ open: true, message: 'Event created successfully!', severity: 'success' });
      navigate(`/event/${response.data.id}`);
    } catch (error) {
      console.error("Error creating event: ", error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = error.response.data.errors.map(err => `${err.field}: ${err.message}`).join(', ');
        setSnackbar({ open: true, message: `Validation errors: ${errorMessages}`, severity: 'error' });
      } else {
        setSnackbar({ open: true, message: 'Failed to create event. Please try again.', severity: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  

const handleStartDateChange = (newDate) => {
    const minAllowedDate = addMinutes(new Date(), 5);
    const maxAllowedDate = addYears(new Date(), 1);
    
    if (newDate < minAllowedDate) {
      setSnackbar({ open: true, message: 'Start date must be at least 5 minutes from now', severity: 'warning' });
    } else if (newDate > maxAllowedDate) {
      setSnackbar({ open: true, message: 'Start date cannot be more than 1 year from now', severity: 'warning' });
    } else {
      setStartDate(newDate);
      // Ajuster la date de fin si nécessaire
      if (endDate && endDate <= newDate) {
        setEndDate(addMinutes(newDate, 30));
      }
    }
  };

  const handleEndDateChange = (newDate) => {
    const maxAllowedEndDate = setSeconds(setMinutes(setHours(addDays(startDate, 1), 23), 59), 59);
    
    if (newDate <= startDate) {
      setSnackbar({ open: true, message: 'End date must be after start date', severity: 'warning' });
    } else if (newDate > maxAllowedEndDate) {
      setSnackbar({ open: true, message: 'End date cannot be more than 23:59 the next day', severity: 'warning' });
      setEndDate(maxAllowedEndDate);
    } else {
      setEndDate(newDate);
    }
  };

  // ... (imports et autres parties du code restent inchangés)

const debouncedAddressLookup = useCallback(
  debounce(async (address) => {
    if (address.length > 3) {
      try {
        setLoadingLocation(true);
        const response = await api.get('/events/getlocation', {
          params: { address }
        });
        if (response.data && response.data.latitude && response.data.longitude) {
          const { latitude, longitude } = response.data;
          setCoordinates({ latitude, longitude });
          setMapCenter([latitude, longitude]);
        } else {
          setSnackbar({ open: true, message: 'No results found for this address.', severity: 'warning' });
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
        if (error.response && error.response.status === 429) {
          setSnackbar({ open: true, message: 'API limit reached. Please try manual coordinate input or try again later.', severity: 'error' });
        } else {
          setSnackbar({ open: true, message: 'Error fetching coordinates. Please try again.', severity: 'error' });
        }
      } finally {
        setLoadingLocation(false);
      }
    }
  }, 500),
  []
);





  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Fade in={activeStep === 0}>
            <Box>
            <BackButton color="primary" onClick={handleBackClick}>
          <ArrowBackIcon />
        </BackButton>
              <Typography variant="h6" gutterBottom>
                Choose Event Type
              </Typography>
              <RadioGroup
                aria-label="event-type"
                name="event-type"
                value={eventType}
                onChange={handleEventTypeChange}
              >
                <StyledFormControlLabel
                  value="private"
                  control={<StyledRadio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <LockIcon color="action" style={{ marginRight: 8 }} />
                      <Box>
                        <Typography variant="subtitle1">Private Event</Typography>
                        <Typography variant="body2" color="textSecondary">Visible only to invited people</Typography>
                      </Box>
                    </Box>
                  }
                />
                <StyledFormControlLabel
                  value="friends"
                  control={<StyledRadio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <PeopleIcon color="action" style={{ marginRight: 8 }} />
                      <Box>
                        <Typography variant="subtitle1">Friends Event</Typography>
                        <Typography variant="body2" color="textSecondary">Visible to all your friends</Typography>
                      </Box>
                    </Box>
                  }
                />
                <StyledFormControlLabel
                  value="public"
                  control={<StyledRadio />}
                  label={
                    <Box display="flex" alignItems="center">
                      <PublicIcon color="action" style={{ marginRight: 8 }} />
                      <Box>
                        <Typography variant="subtitle1">Public Event</Typography>
                        <Typography variant="body2" color="textSecondary">Visible to everyone nearby</Typography>
                      </Box>
                    </Box>
                  }
                />
              </RadioGroup>
            </Box>
          </Fade>
        );
        case 1:
          return (
            <Fade in={activeStep === 1}>
              <Box>
                <BackButton color="primary" onClick={handleBackClick}>
                  <ArrowBackIcon />
                </BackButton>
                <CharacterCountInput
                  id="title"
                  label="Event Title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  error={title.trim().length < 3}
                  helperText={title.trim().length < 3 ? "Title must be at least 3 characters" : ""}
                  maxLength={100}
                  icon={<EventIcon color="action" />}
                  autoFocus
                  required
                />
                
                <CharacterCountInput
                  id="venueName"
                  label="Venue Name (Optional)"
                  name="venueName"
                  value={venueName}
                  onChange={(e) => setVenueName(e.target.value)}
                  maxLength={100}
                  icon={<PlaceIcon color="action" />}
                />
  
                <CharacterCountInput
                  id="price"
                  label="Price (Optional)"
                  name="price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  maxLength={100}
                  icon={<MonetizationOnIcon color="action" />}
                  placeholder="e.g., Free, 10€, 5-15€, or Price on request"
                />
  
                <CharacterCountInput
                  id="description"
                  label="Event Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  maxLength={1000}
                  minLength={10}
                  icon={<DescriptionIcon color="action" />}
                  error={description.trim().length < 10 || description.trim().length > 1000}
                  helperText={
                    description.trim().length < 10 
                      ? "Description must be at least 10 characters" 
                      : description.trim().length > 1000 
                        ? "Description must not exceed 1000 characters" 
                        : `${description.length}/1000`
                  }
                  multiline
                  rows={4}
                  required
                />
  
                {eventType === 'public' && (
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="event-category-label">Event Category</InputLabel>
                    <Select
                      labelId="event-category-label"
                      id="event-category"
                      value={eventCategory}
                      onChange={handleEventCategoryChange}
                      label="Event Category"
                      startAdornment={<CategoryIcon color="action" style={{ marginRight: 8 }} />}
                    >
                      {eventCategories.map((category) => (
                        <MenuItem key={category.value} value={category.value}>
                          {category.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              {(eventType === 'private') && (
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<GroupIcon />}
                    onClick={() => setOpenInviteDialog(true)}
                  >
                    Invite Friends & Groups
                  </Button>
                </Box>
              )}
              {invitedUsers.length > 0 && (
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Invited: {invitedUsers.length} people
                    </Typography>
                    <Divider style={{ margin: '10px 0' }} />
                    <Box display="flex" flexWrap="wrap">
                      {invitedUsers.map((user) => (
                        <StyledChip
                          key={user.id}
                          avatar={<Avatar>{user.name[0]}</Avatar>}
                          label={user.name}
                          onDelete={() => removeInvitedUser(user.id)}
                        />
                      ))}
                    </Box>
                  </CardContent>
                </StyledCard>
              )}
            </Box>
          </Fade>
        );
        case 2:
          return (
            <Fade in={activeStep === 2}>
              <Box>
                <BackButton color="primary" onClick={handleBackClick}>
                  <ArrowBackIcon />
                </BackButton>
        
                {/* Date & Time Selection Section */}
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EventIcon color="primary" />
                    Event Date & Time
                  </Typography>
                  
                  <Grid container spacing={3}>
                    {/* Start Date & Time */}
                    <Grid item xs={12} md={6}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Start Date
                        </Typography>
                        <DatePicker
                          value={startDate}
                          onChange={(newDate) => {
                            const newDateTime = newDate ? setHours(setMinutes(newDate, getMinutes(startDate)), getHours(startDate)) : startDate;
                            handleStartDateChange(newDateTime);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                              sx={{ mb: 2 }}
                            />
                          )}
                          minDate={addMinutes(new Date(), 5)}
                          maxDate={addYears(new Date(), 1)}
                        />
                        
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                          Start Time
                        </Typography>
                        <TimePicker
                          value={startDate}
                          onChange={(newTime) => {
                            if (newTime) {
                              const newDateTime = setHours(
                                setMinutes(startDate, getMinutes(newTime)),
                                getHours(newTime)
                              );
                              handleStartDateChange(newDateTime);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              required
                            />
                          )}
                          minutesStep={5}
                          ampm={false}
                          views={['hours', 'minutes']}
                        />
                      </Box>
                    </Grid>
        
                    {/* End Date & Time */}
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography variant="body2" color="textSecondary">
                            End Date & Time (Optional)
                          </Typography>
                          <Switch
                            size="small"
                            checked={!!endDate}
                            onChange={(e) => {
                              if (e.target.checked) {
                                handleEndDateChange(addHours(startDate, 2));
                              } else {
                                handleEndDateChange(null);
                              }
                            }}
                            sx={{ ml: 1 }}
                          />
                        </Box>
        
                        {endDate && (
                          <>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                              End Date
                            </Typography>
                            <DatePicker
                              value={endDate}
                              onChange={(newDate) => {
                                if (newDate) {
                                  const newDateTime = setHours(
                                    setMinutes(newDate, getMinutes(endDate)),
                                    getHours(endDate)
                                  );
                                  handleEndDateChange(newDateTime);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  sx={{ mb: 2 }}
                                />
                              )}
                              minDate={startDate}
                              maxDate={addDays(startDate, 1)}
                            />
        
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                              End Time
                            </Typography>
                            <TimePicker
                              value={endDate}
                              onChange={(newTime) => {
                                if (newTime) {
                                  const newDateTime = setHours(
                                    setMinutes(endDate, getMinutes(newTime)),
                                    getHours(newTime)
                                  );
                                  handleEndDateChange(newDateTime);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                />
                              )}
                              minutesStep={5}
                              ampm={false}
                              views={['hours', 'minutes']}
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
        
                  {/* Duration Display */}
                  {endDate && (
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                      <AccessTimeIcon color="action" fontSize="small" />
                      <Typography variant="body2" color="textSecondary">
                        Duration: {formatDuration(differenceInMinutes(endDate, startDate))}
                      </Typography>
                    </Box>
                  )}
                </Paper>
        
                {/* Location Section */}
                <Paper elevation={0} sx={{ p: 3, mb: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="subtitle1" gutterBottom sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PlaceIcon color="primary" />
                    Event Location
                  </Typography>
        
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="address"
                    label="Event Location"
                    name="address"
                    required
                    error={address.trim().length < 5 || address.trim().length > 120}
                    helperText={
                      <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LocationSearchingIcon fontSize="small" sx={{ color: 'primary.main' }} />
                        Events are visible within 30km radius - please ensure the address is accurate
                      </Box>
                    }
                    value={address}
                    onChange={handleAddressChange}
                    InputProps={{
                      startAdornment: <PlaceIcon color="action" />,
                      endAdornment: loadingLocation ? (
                        <CircularProgress size={20} />
                      ) : coordinates ? (
                        <Tooltip title="Location verified">
                          <CheckCircleIcon color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Enter a valid address">
                          <InfoIcon color="action" />
                        </Tooltip>
                      )
                    }}
                    margin="normal"
                  />
        
                  {coordinates && (
                    <Box mt={1} display="flex" alignItems="center" gap={1}>
                      <LocationOnIcon fontSize="small" color="success" />
                      <Typography variant="body2" color="success.main">
                        Location found: {address}
                      </Typography>
                    </Box>
                  )}
        
                  {coordinates && (
                    <Box mt={2} height="300px" position="relative">
                      <CustomMapContainer 
                        center={mapCenter}
                        zoom={13}
                        coordinates={[coordinates.latitude, coordinates.longitude]}
                        events={[{
                          id: 'temp',
                          coordinates: {latitude: coordinates.latitude, longitude: coordinates.longitude},
                          username: username || 'You',
                          title: title || 'Your Event'
                        }]}
                        onViewDetails={() => {}}
                        key={JSON.stringify(coordinates)}
                      />
                      <Box
                        position="absolute"
                        bottom={16}
                        left={16}
                        bgcolor="rgba(255, 255, 255, 0.9)"
                        p={1}
                        borderRadius={1}
                        boxShadow={1}
                        zIndex={1000}
                      >
                        <Typography variant="caption" display="flex" alignItems="center" gap={0.5}>
                          <RadioButtonUncheckedIcon fontSize="small" />
                          30km visibility radius
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Paper>
              </Box>
            </Fade>
          );
        case 3:
          return (
            <Fade in={activeStep === 3}>
              <Box>
              <BackButton color="primary" onClick={handleBackClick}>
          <ArrowBackIcon />
        </BackButton>
                <ImageUpload onImageUploaded={(imageUrl) => setImage(imageUrl)} />
                <Box mt={2}>
                  <HashtagInput onChange={handleHashtagsChange} />
                </Box>
              </Box>
            </Fade>
          );
        case 4:
          return (
            <Fade in={activeStep === 4}>
              <Box>
                <Typography variant="h6" gutterBottom>Review Your Event</Typography>
                <Typography><strong>Title:</strong> {title}</Typography>
                <Typography><strong>Description:</strong> {description}</Typography>
                <Typography><strong>Type:</strong> {eventType}</Typography>
                {eventType === 'public' && (
                  <Typography><strong>Category:</strong> {eventCategories.find(cat => cat.value === eventCategory)?.label}</Typography>
                )}
                <Typography><strong>Start Date:</strong> {startDate.toLocaleString()}</Typography>
                {endDate && <Typography><strong>End Date:</strong> {endDate.toLocaleString()}</Typography>}
                <Typography><strong>Address:</strong> {address}</Typography>
                <Typography><strong>Hashtags:</strong> {hashtags.join(', ')}</Typography>
                {image && (
                  <Box mt={2}>
                    <img src={image} alt="Event" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                  </Box>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                      name="acceptTerms"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2">
                      I have read and accept the <Link href="#" onClick={(e) => { e.preventDefault(); setOpenTerms(true); }}>Terms and Conditions</Link>
                    </Typography>
                  }
                />
              </Box>
            </Fade>
          );
        default:
          return 'Unknown step';
      }
    };







    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Container component="main" maxWidth="md">
          <StyledPaper elevation={6}>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              Create New Event
            </Typography>
            <StyledStepper>
  {steps.map((_, index) => (
    <StepDot key={index} active={index === activeStep} completed={index < activeStep} />
  ))}
</StyledStepper>
            <StyledForm onSubmit={(e) => e.preventDefault()}>
              {renderStepContent(activeStep)}
              <Box mt={3} display="flex" justifyContent="space-between">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading || !acceptedTerms}
                  >
                    {loading ? <CircularProgress size={24} /> : 'Create Event'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={!isStepValid()}
                  >
                    Next
                  </Button>
                )}
              </Box>
            </StyledForm>
          </StyledPaper>
  
          <Dialog open={openInviteDialog} onClose={() => setOpenInviteDialog(false)}>
            <DialogTitle>Invite Friends & Groups</DialogTitle>
            <DialogContent>
              <Typography variant="body2" gutterBottom>
                Friends: {friends.length}, Groups: {groups.length}
              </Typography>
              <Autocomplete
                multiple
                id="invite-friends-and-groups"
                options={[
                  ...friends.map(friend => ({ ...friend, type: 'friend' })),
                  ...groups.map(group => ({ ...group, type: 'group' }))
                ]}
                getOptionLabel={(option) => option.type === 'friend' ? option.displayName : `Group: ${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Friends & Groups"
                    placeholder="Type to search"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      avatar={<Avatar>{option.type === 'group' ? 'G' : option.displayName[0]}</Avatar>}
                      label={option.type === 'friend' ? option.displayName : `Group: ${option.name}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event, newValue) => {
                  handleInvite(newValue);
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenInviteDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
  
          <Dialog open={openTerms} onClose={() => setOpenTerms(false)} maxWidth="md" fullWidth>
            <DialogTitle>Terms of Service</DialogTitle>
            <DialogContent>
              <TermsOfService />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenTerms(false)}>Close</Button>
            </DialogActions>
          </Dialog>
  
          <Snackbar 
            open={snackbar.open} 
            autoHideDuration={6000} 
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Container>
      </LocalizationProvider>
    );
  }
  
  export default CreateEvent;