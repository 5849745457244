import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { GeoFirestore } from 'geofirestore';



const firebaseConfig = {
  apiKey: "AIzaSyA2k87IFU8obk7IgUNfRQZyq67IJwtjy1M",
  authDomain: "out-tonight-6a426.firebaseapp.com",
  projectId: "out-tonight-6a426",
  storageBucket: "out-tonight-6a426.appspot.com",
  messagingSenderId: "354435204688",
  appId: "1:354435204688:web:b7e54c3bf4369c23963355",
  measurementId: "G-1LTC56NX4P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app); // Utilisation de Firestore avec Firebase 10
const storage = getStorage(app);

// Initialize GeoFirestore
const geoFirestore = new GeoFirestore(db);

export { auth, db, storage, geoFirestore };
export default app;