import React, { useRef, useEffect } from 'react';
import { Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, addDays, isWeekend } from 'date-fns';

const DateChip = styled(Chip)(({ theme, isSelected, isWeekend }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: isSelected 
    ? theme.palette.primary.main 
    : isWeekend 
      ? theme.palette.grey[500] // Changé de 100 à 200 pour plus de contraste
      : 'transparent',
  color: isSelected 
    ? theme.palette.primary.contrastText 
    : theme.palette.text.primary,
  border: `1px solid ${isSelected 
    ? theme.palette.primary.main 
    : isWeekend
      ? theme.palette.grey[400] // Changé de 300 à 400 pour une bordure plus visible
      : theme.palette.divider}`,
  '&:hover': {
    backgroundColor: isSelected 
      ? theme.palette.primary.dark 
      : isWeekend
        ? theme.palette.grey[300] // Changé de 200 à 300 pour un hover plus visible
        : theme.palette.action.hover,
  },
  flexDirection: 'column',
  height: 'auto',
  minWidth: '80px',
  '& .MuiChip-label': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '100%',
  },
}));

const DayLabel = styled(Typography)(({ theme, isWeekend }) => ({
  fontSize: '0.7rem',
  opacity: isWeekend ? 0.9 : 0.7, // Augmenté l'opacité pour les weekends
  fontWeight: isWeekend ? 500 : 400, // Légèrement plus gras pour les weekends
}));

const ScrollableBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(1),
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '3px',
  },
}));

const DateSelector = ({ selectedDate, onDateSelect, onLoadMoreDates, displayedDates = 30 }) => {
  const scrollContainerRef = useRef(null);
  const today = new Date();
  const dates = Array.from({ length: displayedDates }, (_, i) => addDays(today, i));

  useEffect(() => {
    if (selectedDate && scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const selectedElement = container.querySelector(`[data-date="${format(selectedDate, 'yyyy-MM-dd')}"]`);
      
      if (selectedElement) {
        const containerWidth = container.offsetWidth;
        const elementWidth = selectedElement.offsetWidth;
        const elementOffsetLeft = selectedElement.offsetLeft;
        const scrollPosition = elementOffsetLeft - (containerWidth / 2) + (elementWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [selectedDate]);

  return (
    <Box mb={2}>
      <Typography variant="subtitle1" gutterBottom>
      </Typography>
      <ScrollableBox ref={scrollContainerRef}>
        {dates.map((date) => {
          const isDateSelected = selectedDate && 
            format(selectedDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
          const isWeekendDay = isWeekend(date);
          
          return (
            <Box
              key={date.toISOString()}
              data-date={format(date, 'yyyy-MM-dd')}
            >
              <DateChip
                label={
                  <>
                    <DayLabel isWeekend={isWeekendDay}>
                      {format(date, 'EEE')}
                    </DayLabel>
                    {format(date, 'MMM d')}
                  </>
                }
                onClick={() => onDateSelect(date)}
                isSelected={isDateSelected}
                isWeekend={isWeekendDay}
              />
            </Box>
          );
        })}
        <Chip
          label="Plus de dates"
          onClick={onLoadMoreDates}
          variant="outlined"
          sx={{ 
            margin: (theme) => theme.spacing(0.5),
            height: '100%',
            minWidth: '80px'
          }}
        />
      </ScrollableBox>
    </Box>
  );
};

export default DateSelector;