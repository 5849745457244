import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, Typography, Grid, Box, Button, Chip, CircularProgress, 
  Paper, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions,
  useTheme, useMediaQuery, Select, MenuItem, TextField, IconButton,
  List, ListItem, ListItemText, Checkbox, FormControlLabel
} from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { categories } from '../constants/categories';
import EventCard from '../components/EventCard';
import EventListItem from '../components/EventListItem';
import EventMap from '../components/EventMap';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '../contexts/AuthContext';
import DateSelector from '../components/DateSelector';
import { api } from '../utils/api';
import FilterListIcon from '@mui/icons-material/FilterList';
import LoginIcon from '@mui/icons-material/Login';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { parseISO, format, isValid, startOfDay, endOfDay, addDays } from 'date-fns';
import debounce from 'lodash/debounce';
import CustomizablePulsatingGlowBorder from '../components/PulsatingGlowBorder';
import { getCertificationStyle } from '../utils/certificationUtils';
import { styled, alpha } from '@mui/material/styles';
import WelcomeCategoriesDialog from './WelcomeCategoriesDialog';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import AddIcon from '@mui/icons-material/Add';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: 0,
  }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  '& .MuiFormControlLabel-label': {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
    }
  }
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '&.MuiChip-outlined': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    height: '28px'
  }
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 200px)',
  width: '100%',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 120px)',
    marginTop: 0,
    borderRadius: 0
  }
}));

const StickyDateHeader = styled(Typography)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 1,
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.1rem',
    padding: theme.spacing(1, 0),
    fontWeight: 500
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1)
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1)
  }
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    width: '100%',
    color: 'inherit',
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    paddingRight: theme.spacing(4),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.75, 0.75, 0.75, 0),
      paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    }
  }
}));

const MobileControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}));

const MobileSelect = styled(Select)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& .MuiSelect-select': {
      fontSize: '0.9rem',
      padding: theme.spacing(1)
    }
  }
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: '-4px',
    width: 'calc(100% + 8px)',
    '& .MuiGrid-item': {
      padding: '4px'
    }
  }
}));

const PublicEventBrowser = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { currentUser } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // États
  const [selectedCategories, setSelectedCategories] = useState(() => {
    const storedCategories = localStorage.getItem('selectedCategories');
    return storedCategories ? JSON.parse(storedCategories) : [];
  });

  const [showOnlyCertified, setShowOnlyCertified] = useState(() => {
    return JSON.parse(localStorage.getItem('showOnlyCertified') || 'false');
  });

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [categoryEventCounts, setCategoryEventCounts] = useState({});
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('viewMode') || 'grid';
  });
  const [sortBy, setSortBy] = useState(() => {
    return localStorage.getItem('sortBy') || 'dateAsc';
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [displayedDates, setDisplayedDates] = useState(30);
  const [searchTerm, setSearchTerm] = useState('');
  const [userLocation, setUserLocation] = useState(null);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [eventCache, setEventCache] = useState({});
  const [favoriteHashtags, setFavoriteHashtags] = useState([]);
  const [blacklistedHashtags, setBlacklistedHashtags] = useState([]);
  const [openHashtagDialog, setOpenHashtagDialog] = useState(false);
  const [selectedHashtags, setSelectedHashtags] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedHashtags') || '[]');
  });

  // Obtenir la localisation
  const getUserLocation = useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
          setUserLocation({ latitude: null, longitude: null });
        }
      );
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  useEffect(() => {
    const hideDialog = localStorage.getItem('hideWelcomeDialog');
    const hasStoredCategories = localStorage.getItem('selectedCategories');
    if (!hideDialog && !hasStoredCategories) {
      setShowWelcomeDialog(true);
    }
  }, []);

  const speedDialActions = [
    { icon: <PersonIcon />, name: 'Profile', handleClick: () => navigate('/profile') },
    { icon: <PeopleIcon />, name: 'Friends', handleClick: () => navigate('/friends') },
    { icon: <EventIcon />, name: 'My Events', handleClick: () => navigate('/my-events') },
    { icon: <AddIcon />, name: 'Create Event', handleClick: () => navigate('/create-event') },
  ];

  const preloadEvents = useCallback(async () => {
    if (!userLocation) return;

    try {
      await api.get('/public-events/preload', {
        params: {
          days: displayedDates,
          categories: selectedCategories.join(','),
          latitude: userLocation.latitude,
          longitude: userLocation.longitude
        }
      });
    } catch (error) {
      console.error('Error preloading events:', error);
    }
  }, [displayedDates, selectedCategories, userLocation]);

  const fetchDailyEvents = useCallback(async (date) => {
    if (!userLocation) return;

    setLoading(true);
    setError(null);

    const dateStr = format(date, 'yyyy-MM-dd');
    const cacheKey = `${dateStr}_${selectedCategories.join('_')}`;

    try {
      if (eventCache[cacheKey] && !searchTerm) {
        setEvents(eventCache[cacheKey]);
        setFilteredEvents(eventCache[cacheKey]);
        setLoading(false);
        return;
      }

      const response = await api.get('/public-events/daily', {
        params: {
          date: dateStr,
          categories: selectedCategories.join(','),
          latitude: userLocation.latitude,
          longitude: userLocation.longitude
        }
      });

      const newEvents = response.data.events;
      
      if (!searchTerm) {
        setEventCache(prev => ({
          ...prev,
          [cacheKey]: newEvents
        }));
      }

      setEvents(newEvents);
      setFilteredEvents(newEvents);
      updateCategoryEventCounts(newEvents);
    } catch (err) {
      console.error('Error fetching events:', err);
      setError('Failed to load events. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [userLocation, selectedCategories, searchTerm, eventCache]);

  useEffect(() => {
    if (userLocation) {
      fetchDailyEvents(selectedDate);
      preloadEvents();
    }
  }, [fetchDailyEvents, preloadEvents, userLocation, selectedDate]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      const filtered = events.filter((event) => {
        // Filtre de certification
        console.log(event.creatorIsCertified)
        if (showOnlyCertified && !event.creatorIsCertified) {
          return false;
        }

        const matchesSearch = !term || (
          event.title.toLowerCase().includes(term.toLowerCase()) ||
          event.description.toLowerCase().includes(term.toLowerCase()) ||
          (event.hashtags && event.hashtags.some(tag => 
            tag.toLowerCase().includes(term.toLowerCase())
          ))
        );
  
        const matchesSelectedHashtags = selectedHashtags.length === 0 || 
          (Array.isArray(event.hashtags) && selectedHashtags.every(tag => event.hashtags.includes(tag)));
        
        const matchesFavoriteHashtags = favoriteHashtags.length === 0 ||
          (Array.isArray(event.hashtags) && event.hashtags.some(tag => favoriteHashtags.includes(tag)));
        
        const notInBlacklist = !Array.isArray(event.hashtags) ||
          !event.hashtags.some(tag => blacklistedHashtags.includes(tag));
  
        return matchesSearch && matchesSelectedHashtags && matchesFavoriteHashtags && notInBlacklist;
      });
      setFilteredEvents(filtered);
    }, 300),
    [events, selectedHashtags, favoriteHashtags, blacklistedHashtags, showOnlyCertified]
  );

  useEffect(() => {
    if (searchTerm || showOnlyCertified) {
      debouncedSearch(searchTerm);
    } else {
      setFilteredEvents(events);
    }
    return () => debouncedSearch.cancel();
  }, [searchTerm, events, debouncedSearch, showOnlyCertified]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    fetchDailyEvents(date);
  };

  const handleLoadMoreDates = () => {
    setDisplayedDates(prev => prev + 30);
    preloadEvents();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCertifiedFilterChange = (event) => {
    const newValue = event.target.checked;
    setShowOnlyCertified(newValue);
    localStorage.setItem('showOnlyCertified', JSON.stringify(newValue));
    
    // Appliquer directement le filtre
    const filtered = events.filter((event) => {
      // Filtre de certification
      if (newValue && !event.creatorIsCertified) {
        return false;
      }
  
      // Filtrage basé sur le terme de recherche
      const matchesSearch = !searchTerm || (
        event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (event.hashtags && event.hashtags.some(tag => 
          tag.toLowerCase().includes(searchTerm.toLowerCase())
        ))
      );
  
      // Filtrage basé sur les hashtags
      const matchesSelectedHashtags = selectedHashtags.length === 0 || 
        (Array.isArray(event.hashtags) && selectedHashtags.every(tag => event.hashtags.includes(tag)));
      
      const matchesFavoriteHashtags = favoriteHashtags.length === 0 ||
        (Array.isArray(event.hashtags) && event.hashtags.some(tag => favoriteHashtags.includes(tag)));
      
      const notInBlacklist = !Array.isArray(event.hashtags) ||
        !event.hashtags.some(tag => blacklistedHashtags.includes(tag));
  
      return matchesSearch && matchesSelectedHashtags && matchesFavoriteHashtags && notInBlacklist;
    });
    
    setFilteredEvents(filtered);
  };

  const updateCategoryEventCounts = useCallback((eventList) => {
    const counts = {};
    eventList.forEach(event => {
      if (event.eventCategory) {
        counts[event.eventCategory] = (counts[event.eventCategory] || 0) + 1;
      }
    });
    setCategoryEventCounts(counts);
  }, []);

  const handleCategoryToggle = useCallback((category) => {
    setSelectedCategories(prev => {
      const updated = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];
      localStorage.setItem('selectedCategories', JSON.stringify(updated));
      return updated;
    });
    setEventCache({}); // Clear cache when changing categories
    fetchDailyEvents(selectedDate);
  }, [selectedDate, fetchDailyEvents]);

  const handleHashtagClick = (hashtag) => {
    setSelectedHashtags(prevHashtags => {
      const updatedHashtags = prevHashtags.includes(hashtag)
        ? prevHashtags.filter(h => h !== hashtag)
        : [...prevHashtags, hashtag];
      localStorage.setItem('selectedHashtags', JSON.stringify(updatedHashtags));
      return updatedHashtags;
    });
  };
  
  const handleAddFavoriteHashtag = (hashtag) => {
    setFavoriteHashtags(prev => [...prev, hashtag]);
  };
  
  const handleRemoveFavoriteHashtag = (hashtag) => {
    setFavoriteHashtags(prev => prev.filter(h => h !== hashtag));
  };
  
  const handleAddBlacklistedHashtag = (hashtag) => {
    setBlacklistedHashtags(prev => [...prev, hashtag]);
  };
  
  const handleRemoveBlacklistedHashtag = (hashtag) => {
    setBlacklistedHashtags(prev => prev.filter(h => h !== hashtag));
  };
  
  const handleCloseHashtagDialog = () => {
    setOpenHashtagDialog(false);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
      localStorage.setItem('viewMode', newMode);
    }
  };

  const handleWelcomeCategoriesChange = (newCategories) => {
    setSelectedCategories(newCategories);
    localStorage.setItem('selectedCategories', JSON.stringify(newCategories));
    setEventCache({});
    fetchDailyEvents(selectedDate);
  };

  const handleViewDetails = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const sortedEvents = useMemo(() => {
    return [...filteredEvents].sort((a, b) => {
      const dateA = new Date(a.startDate);
      const dateB = new Date(b.startDate);

      switch (sortBy) {
        case 'dateDesc':
          return dateB - dateA;
        case 'dateAsc':
          return dateA - dateB;
        case 'distanceAsc':
          return (a.distance || Infinity) - (b.distance || Infinity);
        case 'distanceDesc':
          return (b.distance || Infinity) - (a.distance || Infinity);
        default:
          return 0;
      }
    });
  }, [filteredEvents, sortBy]);

  const groupedEvents = useMemo(() => {
    return sortedEvents.reduce((acc, event) => {
      const date = format(parseISO(event.startDate), 'yyyy-MM-dd');
      if (!acc[date]) acc[date] = [];
      acc[date].push(event);
      return acc;
    }, {});
  }, [sortedEvents]);

  const renderMobileHeader = () => (
    <Box mb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Typography variant="h5" component="h1">
          Discover Events
        </Typography>
        
        
          <Box display="flex" gap={1}>
            <IconButton
              size="small"
              onClick={() => setOpenCategoryDialog(true)}
              color="primary"
            >
              <FilterListIcon />
            </IconButton>
            {!currentUser && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => navigate('/login')}
              startIcon={<LoginIcon />}
            >
              Login
            </Button>
             )}
          </Box>
       
      </Box>
    </Box>
  );

  const renderMobileControls = () => (
    <MobileControlsContainer mb={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 1 }}>
        <MobileSelect
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          size="small"
          sx={{ flexGrow: 1 }}
        >
          <MenuItem value="dateAsc">Starting Soonest</MenuItem>
          <MenuItem value="dateDesc">Starting Latest</MenuItem>
          <MenuItem value="distanceAsc">Nearest</MenuItem>
          <MenuItem value="distanceDesc">Farthest</MenuItem>
        </MobileSelect>
        <StyledFormControlLabel
          control={
            <Checkbox
              size="small"
              checked={showOnlyCertified}
              onChange={handleCertifiedFilterChange}
            />
          }
          label="Certified"
        />
      </Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search events..."
          value={searchTerm}
          onChange={handleSearchChange}
          variant="standard"
        />
        {searchTerm && (
          <IconButton
            size="small"
            onClick={() => setSearchTerm('')}
            sx={{ position: 'absolute', right: 8 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Search>
      <ToggleButtonGroup
        value={viewMode}
        exclusive
        onChange={handleViewModeChange}
        aria-label="view mode"
        size="small"
        fullWidth
      >
        <ToggleButton value="grid">
          <ViewModuleIcon />
        </ToggleButton>
        <ToggleButton value="list">
          <ViewListIcon />
        </ToggleButton>
        <ToggleButton value="map">
          <PublicIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      {selectedHashtags.length > 0 && (
        <Box mt={2} mb={2}>
          {selectedHashtags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              onDelete={() => handleHashtagClick(tag)}
              style={{ margin: '0 4px 4px 0' }}
            />
          ))}
        </Box>
      )}
    </MobileControlsContainer>
  );

  const renderEventsByDay = () => {
    return Object.entries(groupedEvents).map(([date, dayEvents]) => (
      <Box key={date} mb={isMobile ? 2 : 4}>
        <StickyDateHeader variant="h6" gutterBottom>
          {format(parseISO(date), 'EEEE, MMMM d, yyyy')}
        </StickyDateHeader>
        <StyledGrid container spacing={isMobile ? 1 : 2}>
          {dayEvents.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <CustomizablePulsatingGlowBorder 
                color1={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.borderColor : '#ccc'} 
                color2={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.badgeColor : '#eee'}
              >
                <EventCard 
                  event={event}
                  onViewDetails={() => handleViewDetails(event.id)}
                  onHashtagClick={handleHashtagClick}
                  certificationStyle={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType) : null}
                  isMobile={isMobile}
                />
              </CustomizablePulsatingGlowBorder>
            </Grid>
          ))}
        </StyledGrid>
      </Box>
    ));
  };

  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        [theme.breakpoints.down('sm')]: {
          padding: 0
        }
      }}
    >
      <StyledPaper>
        {isMobile ? (
          <>
            {renderMobileHeader()}
            <DateSelector 
              selectedDate={selectedDate} 
              onDateSelect={handleDateSelect}
              onLoadMoreDates={handleLoadMoreDates}
              displayedDates={displayedDates}
            />
            {renderMobileControls()}
          </>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h4" component="h1">
                Discover Public Events
              </Typography>
              
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenCategoryDialog(true)}
                    startIcon={<FilterListIcon />}
                    sx={{ mr: 1 }}
                  >
                    Categories
                  </Button>
                  {!currentUser && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/login')}
                    startIcon={<LoginIcon />}
                  >
                    Log In
                  </Button>
                  
              )}
                </Box>
            </Box>

            <Typography variant="body2" color="textSecondary" paragraph>
              Browse public events without an account. Log in to access all features!
            </Typography>

            <DateSelector 
              selectedDate={selectedDate} 
              onDateSelect={handleDateSelect}
              onLoadMoreDates={handleLoadMoreDates}
              displayedDates={displayedDates}
            />

            <Box mb={3}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search events..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  variant="standard"
                />
                {searchTerm && (
                  <IconButton
                    onClick={() => setSearchTerm('')}
                    sx={{ position: 'absolute', right: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Search>
              {selectedHashtags.length > 0 && (
                <Box mt={2} mb={2}>
                  {selectedHashtags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      onDelete={() => handleHashtagClick(tag)}
                      style={{ margin: '0 4px 4px 0' }}
                    />
                  ))}
                </Box>
              )}
            </Box>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Box display="flex" alignItems="center">
                <Select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <MenuItem value="dateAsc">Starting Soonest</MenuItem>
                  <MenuItem value="dateDesc">Starting Latest</MenuItem>
                  <MenuItem value="distanceAsc">Nearest</MenuItem>
                  <MenuItem value="distanceDesc">Farthest</MenuItem>
                </Select>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={showOnlyCertified}
                      onChange={handleCertifiedFilterChange}
                    />
                  }
                  label="Show certified events only"
                />
              </Box>

              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="view mode"
              >
                <ToggleButton value="grid">
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="map">
                  <PublicIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </>
        )}

        {loading && (
          <Box display="flex" justifyContent="center" width="100%" mt={2}>
            <CircularProgress />
          </Box>
        )}

        {!loading && filteredEvents.length === 0 && (
          <Typography variant="body1" align="center" mt={2}>
            No events found for the selected criteria.
          </Typography>
        )}

        {!loading && filteredEvents.length > 0 && (
          <>
            {viewMode === 'grid' && renderEventsByDay()}

            {viewMode === 'list' && (
              <Box sx={{ 
                [theme.breakpoints.down('sm')]: { 
                  mx: -1 
                }
              }}>
                {Object.entries(groupedEvents).map(([date, dayEvents]) => (
                  <Box key={date} mb={isMobile ? 2 : 4}>
                    <StickyDateHeader variant="h6" gutterBottom>
                      {format(parseISO(date), 'EEEE, MMMM d, yyyy')}
                    </StickyDateHeader>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: isMobile ? 1 : 2
                    }}>
                      {dayEvents.map((event) => (
                        <Box
                          key={event.id}
                          sx={{
                            [theme.breakpoints.down('sm')]: {
                              px: 1
                            }
                          }}
                        >
                          <EventListItem
                            event={event}
                            onViewDetails={() => handleViewDetails(event.id)}
                            onHashtagClick={handleHashtagClick}
                            certificationStyle={
                              event.creatorIsCertified 
                                ? getCertificationStyle(event.creatorCertificationType) 
                                : null
                            }
                            isMobile={isMobile}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {viewMode === 'map' && (
              <MapWrapper>
                <EventMap 
                  events={sortedEvents}
                  userLocation={userLocation}
                  onViewDetails={handleViewDetails}
                />
              </MapWrapper>
            )}
          </>
        )}

        <WelcomeCategoriesDialog
          open={showWelcomeDialog}
          onClose={() => {
            setShowWelcomeDialog(false);
            localStorage.setItem('hideWelcomeDialog', 'true');
          }}
          categories={categories}
          selectedCategories={selectedCategories}
          onCategoriesChange={handleWelcomeCategoriesChange}
          todayEventCounts={categoryEventCounts}
        />

        <Dialog 
          open={openCategoryDialog} 
          onClose={() => setOpenCategoryDialog(false)}
          fullScreen={isMobile}
        >
          <DialogTitle>Select Event Categories</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {categories.map((category) => (
                <Grid item key={category.value}>
                  <CategoryChip
                    label={`${category.label} (${categoryEventCounts[category.value] || 0})`}
                    onClick={() => handleCategoryToggle(category.value)}
                    color="primary"
                    variant={selectedCategories.includes(category.value) ? "filled" : "outlined"}
                    size={isMobile ? "small" : "medium"}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ 
            [theme.breakpoints.down('sm')]: { 
              padding: theme.spacing(1),
              position: 'sticky',
              bottom: 0,
              backgroundColor: theme.palette.background.paper,
              borderTop: `1px solid ${theme.palette.divider}`
            }
          }}>
            <Button 
              onClick={() => setOpenCategoryDialog(false)} 
              color="primary"
              size={isMobile ? "small" : "medium"}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openHashtagDialog} onClose={handleCloseHashtagDialog}>
          <DialogTitle>Manage Hashtags</DialogTitle>
          <DialogContent>
            <Typography variant="h6">Favorite Hashtags</Typography>
            <List>
              {favoriteHashtags.map((hashtag) => (
                <ListItem key={hashtag}>
                  <ListItemText primary={hashtag} />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFavoriteHashtag(hashtag)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
            <TextField
              label="Add Favorite Hashtag"
              variant="outlined"
              fullWidth
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddFavoriteHashtag(e.target.value);
                  e.target.value = '';
                }
              }}
            />

            <Typography variant="h6" style={{ marginTop: '1rem' }}>Blacklisted Hashtags</Typography>
            <List>
              {blacklistedHashtags.map((hashtag) => (
                <ListItem key={hashtag}>
                  <ListItemText primary={hashtag} />
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveBlacklistedHashtag(hashtag)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
            <TextField
              label="Add Blacklisted Hashtag"
              variant="outlined"
              fullWidth
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleAddBlacklistedHashtag(e.target.value);
                  e.target.value = '';
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseHashtagDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar 
          open={!!error} 
          autoHideDuration={6000} 
          onClose={() => setError(null)}
          anchorOrigin={{ 
            vertical: isMobile ? 'top' : 'bottom', 
            horizontal: 'center' 
          }}
          sx={{
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              '& .MuiSnackbarContent-root': {
                width: '100%',
                borderRadius: 0
              }
            }
          }}
        >
          <Alert 
            onClose={() => setError(null)} 
            severity="error"
            sx={{
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem'
              }
            }}
          >
            {error}
          </Alert>
        </Snackbar>
      </StyledPaper>

      {currentUser && (
        <SpeedDial
          ariaLabel="Navigation SpeedDial"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          icon={<SpeedDialIcon />}
        >
          {speedDialActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.handleClick}
            />
          ))}
        </SpeedDial>
      )}
    </Container>
  );
};

export default PublicEventBrowser;