import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem,
  ListItemText, ListItemSecondaryAction, IconButton, Autocomplete, TextField,
  Snackbar, Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useAuth } from '../contexts/AuthContext';
import { api } from '../utils/api';


const GroupManagement = ({ open, group, onClose }) => {
  const [members, setMembers] = useState([]);
  const [availableFriends, setAvailableFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const { getIdToken } = useAuth();

  useEffect(() => {
    if (group) { // Only fetch data when group is valid
      fetchGroupMembers();
      fetchAvailableFriends();
    }
  }, [group]);

  const fetchGroupMembers = async () => {
    if (!group) return; // Guard clause to avoid errors when group is null
    try {
      const token = await getIdToken();
      const response = await api.get(`/friends/group/${group.id}/members`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching group members:', error);
      setSnackbar({ open: true, message: 'Failed to fetch group members', severity: 'error' });
    }
  };

  const fetchAvailableFriends = async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/friends', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAvailableFriends(response.data.filter(friend => 
        !members.some(member => member.id === friend.uid)
      ));
    } catch (error) {
      console.error('Error fetching available friends:', error);
      setSnackbar({ open: true, message: 'Failed to fetch available friends', severity: 'error' });
    }
  };

  const handleAddMember = async () => {
    if (!selectedFriend || !group) return; // Check if group exists before proceeding
    try {
      const token = await getIdToken();
      await api.post(`/friends/group/${group.id}/add`, { friendId: selectedFriend.uid }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSelectedFriend(null);
      fetchGroupMembers();
      fetchAvailableFriends();
      setSnackbar({ open: true, message: 'Friend added to group successfully', severity: 'success' });
    } catch (error) {
      console.error('Error adding member:', error);
      setSnackbar({ open: true, message: error.response?.data?.error || 'Failed to add member', severity: 'error' });
    }
  };

  const handleRemoveMember = async (memberId) => {
    if (!group) return; // Guard clause for group null case
    try {
      const token = await getIdToken();
      await api.post(`/friends/group/${group.id}/remove`, { memberId }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchGroupMembers();
      fetchAvailableFriends();
      setSnackbar({ open: true, message: 'Member removed from group successfully', severity: 'success' });
    } catch (error) {
      console.error('Error removing member:', error);
      setSnackbar({ open: true, message: error.response?.data?.error || 'Failed to remove member', severity: 'error' });
    }
  };

  if (!group) return null; // Prevent rendering if group is null

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{group ? `Manage ${group.name}` : 'Manage Group'}</DialogTitle>
        <DialogContent>
          <List>
            {members.map((member) => (
              <ListItem key={member.id}>
                <ListItemText primary={member.displayName} secondary={member.email} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleRemoveMember(member.id)}
                              disabled={member.id === group.creator}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          <Autocomplete
            options={availableFriends}
            getOptionLabel={(option) => option.displayName || option.email}
            value={selectedFriend}
            onChange={(event, newValue) => {
              setSelectedFriend(newValue);
            }}
            renderInput={(params) => <TextField {...params} label="Add friend to group" />}
          />
          <Button
            startIcon={<PersonAddIcon />}
            onClick={handleAddMember}
            disabled={!selectedFriend}
            sx={{ mt: 2 }}
          >
            Add to Group
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupManagement;