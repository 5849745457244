// src/pages/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { auth } from '../firebase';
import { getRedirectResult } from 'firebase/auth';
import { 
  Container, 
  Paper, 
  TextField, 
  Button, 
  Typography, 
  Box,
  Divider, 
  Avatar,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import Logo from '../assets/logo.png';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix pour éviter le dépassement
  marginTop: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const StyledLogo = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
  marginBottom: theme.spacing(2),
}));

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { signup, login, loginWithGoogle } = useAuth();
  
    useEffect(() => {
      const checkRedirectResult = async () => {
        try {
          const result = await getRedirectResult(auth);
          if (result) {
            // L'utilisateur s'est connecté avec succès via la redirection
            navigate('/');
          }
        } catch (error) {
          console.error("Error checking redirect result:", error);
          // Gérer l'erreur ici (par exemple, afficher un message à l'utilisateur)
        } finally {
          setLoading(false);
        }
      };
  
      checkRedirectResult();
    }, [navigate]);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        if (isSignUp) {
          await signup(email, password);
        } else {
          await login(email, password);
        }
        navigate('/');
      } catch (error) {
        alert(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    const handleGoogleLogin = async () => {
      try {
        await loginWithGoogle();
        // Ne pas naviguer ici, la redirection sera gérée par Firebase
      } catch (error) {
        alert(error.message);
      }
    };
  
    if (loading) {
      return (
        <Container component="main" maxWidth="xs">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        </Container>
      );
    }

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper elevation={6}>
        {/* Affichage du logo */}
        <StyledLogo alt="Out Tonight Logo" src={Logo} />
        <Typography component="h1" variant="h5">
          {isSignUp ? 'Sign Up' : 'Sign In'} HuB
        </Typography>
        
        {/* Phrase d'accroche */}
        <Typography variant="body2" color="textSecondary" align="center" sx={{ marginBottom: 2 }}>
          Create and join events anytime, anywhere.
        </Typography>

        <StyledForm onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </StyledButton>
        </StyledForm>
        
        <Box my={2}>
          <Divider>OR</Divider>
        </Box>
        
        <StyledButton
          fullWidth
          variant="outlined"
          color="primary"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
        >
          Sign in with Google
        </StyledButton>
        
        <Box mt={2}>
          <Button
            fullWidth
            color="secondary"
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
          </Button>
        </Box>
      </StyledPaper>
    </Container>
  );
}

export default Login;
