import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

const createCustomIcon = (label) => {
  return L.divIcon({
    className: 'custom-icon',
    html: `<div style="background-color: white; border: 1px solid #1976d2; border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center;">
             <span style="color: #1976d2; font-weight: bold; font-size: 12px;">${label[0]}</span>
           </div>
           <div style="background-color: white; border: 1px solid #1976d2; border-radius: 3px; padding: 2px 4px; margin-top: 2px; font-size: 10px; white-space: nowrap;">
             ${label}
           </div>`,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  });
};

function CustomMapContainer({ center, zoom, coordinates, events, onViewDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MapContainer 
      center={center} 
      zoom={zoom} 
      style={{ 
        height: isMobile ? '40vh' : '100%', 
        width: '100%', 
        marginBottom: isMobile ? '20px' : '0'
      }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <ChangeView center={center} zoom={zoom} />
      {events.map((event) => (
        <Marker 
          key={event.id}
          position={[event.coordinates.latitude, event.coordinates.longitude]} 
          icon={createCustomIcon(event.username)}
        >
          <Popup>
            <Box sx={{ maxWidth: isMobile ? 200 : 300 }}>
              <Typography variant="subtitle1">{event.title}</Typography>
              <Typography variant="caption">Created by: {event.username}</Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  cursor: 'pointer', 
                  color: 'primary.main',
                  '&:hover': { textDecoration: 'underline' }
                }}
                onClick={() => onViewDetails(event.id)}
              >
                View Details
              </Typography>
            </Box>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default CustomMapContainer;