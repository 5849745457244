import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Container, Typography, Button, Box, Grid, Chip, IconButton,
  Dialog, DialogContent, Snackbar, Alert, CircularProgress, 
  Avatar, Paper, Fade, Zoom, useMediaQuery, useTheme, Card, CardContent, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  AccessTime, Room, People, Chat, Report, ExitToApp, 
  Visibility, Share as ShareIcon, ArrowBack, Person, Close,
  Category, EventAvailable, Info
} from '@mui/icons-material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { parseISO, format, isValid } from 'date-fns';
import L from 'leaflet';
import ReportDialog from '../components/ReportDialog';
import { api } from '../utils/api';
import SafeDescriptionComponent from '../components/SafeDescriptionComponent';


// Leaflet icon setup
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: 0,
  }
}));

const EventImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
    borderRadius: 0,
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '8px 8px 0 0',
  backgroundColor: theme.palette.grey[900],
  cursor: 'pointer',
}));

const EventImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '& .MuiChip-icon': {
    color: 'inherit',
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.25),
    height: '28px',
    '& .MuiChip-label': {
      fontSize: '0.75rem',
      padding: '0 8px',
    },
    '& .MuiChip-icon': {
      fontSize: '1rem',
      marginLeft: '4px',
    },
  }
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: '300px',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    height: '200px',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  textTransform: 'none',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0.5),
    fontSize: '0.75rem',
    padding: '6px 12px',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '& .MuiButton-startIcon > *:first-of-type': {
      fontSize: '1.1rem',
    },
  }
}));

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgb(0, 0, 0)',
    }
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    right: theme.spacing(1),
    top: theme.spacing(1),
  }
}));

const InfoCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(1),
    '& .MuiCardContent-root': {
      padding: theme.spacing(1),
    }
  }
}));

const HashtagContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  }
}));

const MobileActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  }
}));

function EventDetails() {
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { currentUser, getIdToken } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [event, setEvent] = useState(location.state?.eventData || null);
  const [isParticipant, setIsParticipant] = useState(false);
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [userLocation, setUserLocation] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [loading, setLoading] = useState(!event);
  const [error, setError] = useState(null);

  const getUserLocation = useCallback(() => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            setUserLocation(location);
            resolve(location);
          },
          (error) => {
            console.error("Error getting user location:", error);
            setSnackbar({ open: true, message: 'Unable to get your location. Some features might be limited.', severity: 'warning' });
            reject(error);
          }
        );
      } else {
        setSnackbar({ open: true, message: 'Geolocation is not supported by your browser.', severity: 'warning' });
        reject(new Error('Geolocation not supported'));
      }
    });
  }, []);

  const fetchEventDetails = useCallback(async (userLocation) => {
    if (event) {
      setIsParticipant(event.participants?.includes(currentUser.uid) || false);
      return;
    }

    try {
      setLoading(true);
      const token = await getIdToken();
      const response = await api.get(`/events/${eventId}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: userLocation
      });
      setEvent(response.data);
      setIsParticipant(response.data.participants?.includes(currentUser.uid) || false);
    } catch (err) {
      console.error("Error fetching event details:", err);
      setError("Failed to fetch event details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [eventId, getIdToken, currentUser, event]);

  useEffect(() => {
    if (!event) {
      getUserLocation().then(fetchEventDetails);
    } else {
      setIsParticipant(event.participants?.includes(currentUser.uid) || false);
    }
  }, [getUserLocation, fetchEventDetails, event, currentUser]);

  const handleJoinLeaveEvent = useCallback(async () => {
    try {
      const token = await getIdToken();
      const action = isParticipant ? 'leave' : 'join';
      const response = await api.post(`/events/${eventId}/${action}`, 
        { latitude: event.coordinates.latitude, longitude: event.coordinates.longitude },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.success) {
        setIsParticipant(!isParticipant);
        setSnackbar({ 
          open: true, 
          message: response.data.message, 
          severity: 'success' 
        });
        
        setEvent(prevEvent => ({
          ...prevEvent,
          participants: action === 'join' 
            ? [...(prevEvent.participants || []), currentUser.uid]
            : (prevEvent.participants || []).filter(id => id !== currentUser.uid),
          participantCount: response.data.participantCount
        }));
      } else {
        setSnackbar({ 
          open: true, 
          message: response.data.message, 
          severity: 'warning' 
        });
      }
    } catch (error) {
      console.error(`Error ${isParticipant ? 'leaving' : 'joining'} event:`, error);
      setSnackbar({ 
        open: true, 
        message: `Error ${isParticipant ? 'leaving' : 'joining'} event. Please try again.`, 
        severity: 'error' 
      });
    }
  }, [eventId, isParticipant, getIdToken, event, currentUser.uid]);

  const openInGoogleMaps = useCallback(() => {
    if (event && event.coordinates) {
      const { latitude, longitude } = event.coordinates;
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(url, '_blank');
    }
  }, [event]);

  const formatEventDate = useMemo(() => (dateString) => {
    if (!dateString) return 'Date not available';
    const date = typeof dateString === 'object' && dateString._seconds
      ? new Date(dateString._seconds * 1000)
      : parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'Invalid date';
  }, []);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !event) {
    return (
      <Container maxWidth="md">
        <StyledPaper>
          <Typography variant="h6" color="error">{error || 'Event not found'}</Typography>
          <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </StyledPaper>
      </Container>
    );
  }

  const renderEventHeader = () => (
    <Box mb={isMobile ? 1 : 2}>
      <IconButton 
        onClick={() => navigate(-1)} 
        sx={{ 
          position: 'absolute', 
          top: isMobile ? 4 : 8, 
          left: isMobile ? 4 : 8, 
          zIndex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          }
        }}
      >
        <ArrowBack />
      </IconButton>
      
      <EventImageContainer onClick={() => setOpenImageDialog(true)}>
        <EventImage 
          src={event.imageUrl || '/placeholder-image.jpg'} 
          alt={event.title} 
          loading="lazy"
        />
      </EventImageContainer>
    </Box>
  );

  const renderEventInfo = () => (
    <Box p={isMobile ? 1.5 : 3}>
      <Typography 
        variant={isMobile ? "h5" : "h4"} 
        gutterBottom
        sx={{
          fontSize: isMobile ? '1.5rem' : '2.125rem',
          lineHeight: isMobile ? 1.2 : 1.235,
        }}
      >
        {event.title}
      </Typography>
      
      <Box 
        display="flex" 
        alignItems="center" 
        mb={isMobile ? 1 : 2}
        sx={{
          '& .MuiAvatar-root': {
            width: isMobile ? 32 : 40,
            height: isMobile ? 32 : 40,
          }
        }}
      >
        <Avatar 
          src={event.creatorPhotoURL} 
          alt={event.username}
        >
          {event.username ? event.username[0].toUpperCase() : 'A'}
        </Avatar>
        <Typography 
          variant={isMobile ? "body2" : "subtitle1"} 
          ml={1}
        >
          Created by {event.username || 'Anonymous'}
        </Typography>
      </Box>

      <InfoCard elevation={isMobile ? 1 : 3}>
        <CardContent>
          <Grid container spacing={isMobile ? 1 : 2}>
            <Grid item xs={12} sm={6}>
              <InfoChip 
                icon={<EventAvailable />} 
                label={formatEventDate(event.startDate)}
                size={isMobile ? "small" : "medium"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InfoChip 
                icon={<Room />} 
                label={event.address}
                size={isMobile ? "small" : "medium"}
              />
            </Grid>
            {event.distance && (
              <Grid item xs={12} sm={6}>
                <InfoChip 
                  icon={<Room />} 
                  label={`${event.distance.toFixed(1)} km away`}
                  size={isMobile ? "small" : "medium"}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <InfoChip 
                icon={<Category />} 
                label={event.eventCategory}
                size={isMobile ? "small" : "medium"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </InfoCard>

      <Typography 
        variant={isMobile ? "body2" : "body1"} 
        mt={isMobile ? 1 : 2}
        sx={{
          fontSize: isMobile ? '0.875rem' : '1rem',
          lineHeight: isMobile ? 1.43 : 1.5,
        }}
      >
        <SafeDescriptionComponent description={event.description} />
      </Typography>

      <HashtagContainer>
        {event.hashtags && event.hashtags.map((tag, index) => (
          <InfoChip 
            key={index} 
            label={`${tag}`}
            size={isMobile ? "small" : "medium"}
          />
        ))}
      </HashtagContainer>

      <Box 
        mt={isMobile ? 1 : 2} 
        display="flex" 
        flexWrap="wrap"
        gap={isMobile ? 0.5 : 1}
      >
        <InfoChip 
          icon={<People />} 
          label={`${event.participants?.length || 0} participants`}
          size={isMobile ? "small" : "medium"}
        />
        <InfoChip 
          icon={<Visibility />} 
          label={`${event.viewCount || 0} views`}
          size={isMobile ? "small" : "medium"}
        />
      </Box>

      <MobileActionsContainer>
        <ActionButton
          variant={isParticipant ? "outlined" : "contained"}
          color={isParticipant ? "secondary" : "primary"}
          startIcon={isParticipant ? <ExitToApp /> : <People />}
          onClick={handleJoinLeaveEvent}
          size={isMobile ? "small" : "medium"}
          fullWidth={isMobile}
        >
          {isParticipant ? 'Leave Event' : 'Join Event'}
        </ActionButton>

        <Box 
          display="flex" 
          gap={isMobile ? 0.5 : 1}
          width={isMobile ? '100%' : 'auto'}
          mt={isMobile ? 0.5 : 0}
        >
          <ActionButton
            variant="outlined"
            color="primary"
            startIcon={<Chat />}
            onClick={() => navigate(`/chat/${eventId}`)}
            size={isMobile ? "small" : "medium"}
            fullWidth={isMobile}
          >
            Chat
          </ActionButton>
          <ActionButton
            variant="outlined"
            color="primary"
            startIcon={<ShareIcon />}
            onClick={() => {/* Implement share functionality */}}
            size={isMobile ? "small" : "medium"}
            fullWidth={isMobile}
          >
            Share
          </ActionButton>
        </Box>
      </MobileActionsContainer>

      {event.coordinates && (
        <MapWrapper>
          <MapContainer 
            center={[event.coordinates.latitude, event.coordinates.longitude]} 
            zoom={13} 
            style={{ height: '100%', width: '100%' }}
            zoomControl={!isMobile}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker 
              position={[event.coordinates.latitude, event.coordinates.longitude]}
              eventHandlers={{
                click: openInGoogleMaps,
              }}
            >
              <Popup>
                <Typography variant={isMobile ? "caption" : "body2"}>
                  {event.title}
                </Typography>
                <Typography variant={isMobile ? "caption" : "body2"}>
                  {event.address}
                </Typography>
                <Button 
                  size="small" 
                  onClick={openInGoogleMaps}
                  sx={{ mt: 1, fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                >
                  Open in Google Maps
                </Button>
              </Popup>
            </Marker>
          </MapContainer>
        </MapWrapper>
      )}

      <Box 
        display="flex" 
        justifyContent="flex-end" 
        mt={isMobile ? 1 : 2}
      >
        <IconButton 
          color="error" 
          onClick={() => setOpenReportDialog(true)}
          size={isMobile ? "small" : "medium"}
        >
          <Report />
        </IconButton>
      </Box>
    </Box>
  );

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ p: isMobile ? 0 : 3 }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !event) {
    return (
      <Container maxWidth="md" sx={{ p: isMobile ? 0 : 3 }}>
        <StyledPaper>
          <Typography variant="h6" color="error">
            {error || 'Event not found'}
          </Typography>
          <Button 
            startIcon={<ArrowBack />} 
            onClick={() => navigate(-1)}
            size={isMobile ? "small" : "medium"}
          >
            Go Back
          </Button>
        </StyledPaper>
      </Container>
    );
  }

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        p: isMobile ? 0 : 3,
        minHeight: '100vh',
      }}
    >
      <Fade in={true} timeout={500}>
        <StyledPaper elevation={isMobile ? 0 : 6}>
          {renderEventHeader()}
          {renderEventInfo()}
        </StyledPaper>
      </Fade>

      <FullScreenDialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        TransitionComponent={Zoom}
      >
        <DialogContent 
          sx={{ 
            p: 0, 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%' 
          }}
        >
          <img 
            src={event.imageUrl || '/placeholder-image.jpg'} 
            alt={event.title} 
            style={{ 
              maxWidth: '100%', 
              maxHeight: '100%', 
              objectFit: 'contain' 
            }}
          />
          <CloseButton 
            onClick={() => setOpenImageDialog(false)}
            size={isMobile ? "small" : "medium"}
          >
            <Close />
          </CloseButton>
        </DialogContent>
      </FullScreenDialog>

      <ReportDialog
        open={openReportDialog}
        onClose={() => setOpenReportDialog(false)}
        eventId={eventId}
        latitude={event.coordinates?.latitude}
        longitude={event.coordinates?.longitude}
        isMobile={isMobile}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ 
          vertical: isMobile ? 'top' : 'bottom', 
          horizontal: 'center' 
        }}
        sx={{
          [theme.breakpoints.down('sm')]: {
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
          }
        }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            borderRadius: isMobile ? 0 : undefined,
            fontSize: isMobile ? '0.875rem' : '1rem',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EventDetails;