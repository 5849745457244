import React, { useState, useCallback } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReportIcon from '@mui/icons-material/Report';
import BlockIcon from '@mui/icons-material/Block';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth } from '../contexts/AuthContext';

const EventOptionsMenu = ({ onReport, onBlock, onViewProfile }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentUser } = useAuth(); 

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOption = useCallback((action) => {
    handleClose();
    if (typeof action === 'function') {
      action();
    }
  }, [handleClose]);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {onReport && (
          <MenuItem onClick={() => handleOption(onReport)}>
            <ListItemIcon>
              <ReportIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Report Event" />
          </MenuItem>
        )}
        {currentUser && onBlock && (
          <MenuItem onClick={() => handleOption(onBlock)}>
            <ListItemIcon>
              <BlockIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Block Creator" />
          </MenuItem>
        )}
        {currentUser && onViewProfile && (
          <MenuItem onClick={() => handleOption(onViewProfile)}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View Creator Profile" />
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default EventOptionsMenu;