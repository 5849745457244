// services/notificationService.js
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { db } from '../firebase';
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

const messaging = getMessaging();

export const requestNotificationPermission = async (userId) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: 'YOUR_VAPID_KEY' });
      await saveTokenToDatabase(userId, token);
      return true;
    }
    return false;
  } catch (error) {
    console.error('An error occurred while requesting permission ', error);
    return false;
  }
};

const saveTokenToDatabase = async (userId, token) => {
  const userRef = doc(db, 'users', userId);
  await setDoc(userRef, { notificationToken: token }, { merge: true });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const updateNotificationSettings = async (userId, settings) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { notificationSettings: settings });
};

export const getNotificationSettings = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  return userDoc.data().notificationSettings || {};
};