import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, arrayUnion, doc } from 'firebase/firestore';
import { 
  Button, TextField, List, ListItem, ListItemText, ListItemSecondaryAction,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions,
  Snackbar, Alert, Tabs, Tab, Box, Typography, Chip
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ShareIcon from '@mui/icons-material/Share';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const StyledTabs = styled(Tabs)({
  marginBottom: '20px',
});

const StyledQRCode = styled(QRCodeSVG)({
  margin: '20px auto',
  display: 'block',
});

function AdvancedContactImport() {
  const [contacts, setContacts] = useState([]);
  const [newContact, setNewContact] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [tabValue, setTabValue] = useState(0);
  const { currentUser } = useAuth();
  const [inviteLink, setInviteLink] = useState('');

  const fetchContacts = useCallback(async () => {
    if (!currentUser) return;
    const q = query(collection(db, 'contacts'), where('userId', '==', currentUser.uid));
    const querySnapshot = await getDocs(q);
    setContacts(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  }, [currentUser]);

  useEffect(() => {
    fetchContacts();
    generateInviteLink();
  }, [fetchContacts]);

  const generateInviteLink = () => {
    // Générer un lien d'invitation unique pour l'utilisateur
    const link = `https://yourapp.com/invite/${currentUser.uid}`;
    setInviteLink(link);
  };

  const handleAddContact = async () => {
    if (!newContact.trim()) {
      setSnackbar({ open: true, message: 'Please enter a valid email or phone number', severity: 'error' });
      return;
    }

    try {
      // Vérifier si le contact existe déjà dans la base de données
      const userQuery = query(collection(db, 'users'), where('email', '==', newContact));
      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        // L'utilisateur existe, ajoutez-le comme contact
        const userData = userSnapshot.docs[0].data();
        await addDoc(collection(db, 'contacts'), {
          userId: currentUser.uid,
          contactId: userSnapshot.docs[0].id,
          email: newContact,
          name: userData.displayName || '',
          status: 'connected'
        });
        setSnackbar({ open: true, message: 'Contact added successfully!', severity: 'success' });
      } else {
        // L'utilisateur n'existe pas, envoyez une invitation
        await addDoc(collection(db, 'invitations'), {
          inviterId: currentUser.uid,
          inviteeEmail: newContact,
          status: 'pending',
          createdAt: new Date()
        });
        setSnackbar({ open: true, message: 'Invitation sent!', severity: 'info' });
      }

      setNewContact('');
      fetchContacts();
    } catch (error) {
      console.error("Error adding contact: ", error);
      setSnackbar({ open: true, message: 'Error adding contact. Please try again.', severity: 'error' });
    }
  };

  const handleImportContacts = () => {
    // Simuler l'importation de contacts
    const importedContacts = [
      { email: 'friend1@example.com', name: 'Friend 1' },
      { email: 'friend2@example.com', name: 'Friend 2' },
      // ... plus de contacts simulés
    ];

    setDialogContent(
      <List>
        {importedContacts.map((contact, index) => (
          <ListItem key={index}>
            <ListItemText primary={contact.name} secondary={contact.email} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="add" onClick={() => handleAddImportedContact(contact)}>
                <AddIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
    setOpenDialog(true);
  };

  const handleAddImportedContact = async (contact) => {
    try {
      await addDoc(collection(db, 'contacts'), {
        userId: currentUser.uid,
        email: contact.email,
        name: contact.name,
        status: 'pending'
      });
      setSnackbar({ open: true, message: 'Contact imported successfully!', severity: 'success' });
    } catch (error) {
      console.error("Error importing contact: ", error);
      setSnackbar({ open: true, message: 'Error importing contact. Please try again.', severity: 'error' });
    }
  };

  const handleShareInvite = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Join me on OurApp!',
        text: 'Check out this awesome app!',
        url: inviteLink,
      }).then(() => {
        setSnackbar({ open: true, message: 'Invite shared successfully!', severity: 'success' });
      }).catch((error) => {
        console.error('Error sharing', error);
      });
    } else {
      // Fallback pour les navigateurs qui ne supportent pas l'API Web Share
      setDialogContent(
        <Box>
          <Typography>Share this link with your friends:</Typography>
          <TextField
            fullWidth
            value={inviteLink}
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(inviteLink);
              setSnackbar({ open: true, message: 'Link copied to clipboard!', severity: 'success' });
            }}
          >
            Copy Link
          </Button>
        </Box>
      );
      setOpenDialog(true);
    }
  };

  const handleShowQRCode = () => {
    setDialogContent(
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h6" gutterBottom>
          Scan this QR Code to add me
        </Typography>
        <QRCodeSVG value={inviteLink} size={256} level="H" />

        <Typography variant="body2" color="textSecondary">
          This QR code contains your unique invite link
        </Typography>
      </Box>
    );
    setOpenDialog(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <StyledTabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Add Contact" icon={<PersonAddIcon />} />
        <Tab label="Import" icon={<ImportContactsIcon />} />
        <Tab label="Share" icon={<ShareIcon />} />
        <Tab label="QR Code" icon={<QrCodeIcon />} />
      </StyledTabs>

      {tabValue === 0 && (
        <>
          <TextField
            fullWidth
            label="Add new contact (email or phone)"
            value={newContact}
            onChange={(e) => setNewContact(e.target.value)}
            margin="normal"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleAddContact}
            style={{ marginTop: '10px' }}
          >
            Add Contact
          </Button>
        </>
      )}

      {tabValue === 1 && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleImportContacts}
          startIcon={<ImportContactsIcon />}
        >
          Import Contacts
        </Button>
      )}

      {tabValue === 2 && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleShareInvite}
          startIcon={<ShareIcon />}
        >
          Share Invite Link
        </Button>
      )}

      {tabValue === 3 && (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleShowQRCode}
          startIcon={<QrCodeIcon />}
        >
          Show QR Code
        </Button>
      )}

      <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>
        My Contacts
      </Typography>
      <List>
        {contacts.map((contact) => (
          <ListItem key={contact.id}>
            <ListItemText 
              primary={contact.name || contact.email} 
              secondary={contact.email}
            />
            <Chip 
              label={contact.status} 
              color={contact.status === 'connected' ? 'primary' : 'default'}
              size="small"
            />
          </ListItem>
        ))}
      </List>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Contact Management</DialogTitle>
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AdvancedContactImport;