import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Container, Paper, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction,
  IconButton, TextField, Button, Typography, Tabs, Tab, Box, Divider,
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert, Chip,
  Avatar, CircularProgress, Fade, useTheme, useMediaQuery, Card, CardActions, CardContent, Collapse
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EmailIcon from '@mui/icons-material/Email';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { QRCodeSVG } from 'qrcode.react';

import GroupManagement from '../components/GroupManagement';
import InvitationHandler from '../components/InvitationHandler';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { api } from '../utils/api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  transition: 'background-color 0.3s, transform 0.2s',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px)',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Friends() {
  const [friends, setFriends] = useState([]);
  const [groups, setGroups] = useState([]);
  const [pendingFriendRequests, setPendingFriendRequests] = useState([]);
  const [sentFriendRequests, setSentFriendRequests] = useState([]);
  const [newFriendEmail, setNewFriendEmail] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);
  const [inviteLink, setInviteLink] = useState('');
  const [showQRCode, setShowQRCode] = useState(false);

  const [expandedGroup, setExpandedGroup] = useState(null);
  const [openGroupManagement, setOpenGroupManagement] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const navigate = useNavigate();
  const { currentUser, getIdToken } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleExpandClick = (groupId) => {
    setExpandedGroup(expandedGroup === groupId ? null : groupId);
  };

  const handleOpenGroupManagement = (group) => {
    setSelectedGroup(group);
    setOpenGroupManagement(true);
  };

  const handleCloseGroupManagement = () => {
    setOpenGroupManagement(false);
    setSelectedGroup(null);
    fetchGroups(); // Refresh groups after management
  };

  const fetchFriends = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/friends', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setFriends(response.data);
    } catch (error) { 
      console.error('Error fetching friends:', error);
      setSnackbar({ open: true, message: 'Error fetching friends', severity: 'error' });
    }
  }, [getIdToken]);

  const fetchGroups = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/friends/groups', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setGroups(response.data);
    } catch (error) {
      console.error('Error fetching groups:', error);
      setSnackbar({ open: true, message: 'Error fetching groups', severity: 'error' });
    }
  }, [getIdToken]);

  const fetchPendingFriendRequests = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/friends/pending', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPendingFriendRequests(response.data);
    } catch (error) {
      console.error('Error fetching pending friend requests:', error);
      setSnackbar({ open: true, message: 'Error fetching pending requests', severity: 'error' });
    }
  }, [getIdToken]);

  const fetchSentFriendRequests = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/friends/sent', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSentFriendRequests(response.data);
    } catch (error) {
      console.error('Error fetching sent friend requests:', error);
      setSnackbar({ open: true, message: 'Error fetching sent requests', severity: 'error' });
    }
  }, [getIdToken]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        fetchFriends(),
        fetchGroups(),
        fetchPendingFriendRequests(),
        fetchSentFriendRequests()
      ]);
      setLoading(false);
    };
    fetchData();
    generateInviteLink();
  }, [fetchFriends, fetchGroups, fetchPendingFriendRequests, fetchSentFriendRequests]);

  const handleBackClick = () => {
    navigate('/');
  };

  const generateInviteLink = () => {
    const link = `${window.location.origin}/invite/${currentUser.uid}`;
    setInviteLink(link);
  };

  const sendFriendRequest = async () => {
    try {
      setLoading(true);
      const token = await getIdToken();
      const response = await api.post('/friends/request', { email: newFriendEmail }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNewFriendEmail('');
      setSnackbar({ open: true, message: 'Friend request sent successfully!', severity: 'success' });
      setSentFriendRequests(prevRequests => [...prevRequests, response.data.requestSent]);
    } catch (error) {
      console.error('Error sending friend request:', error);
      let errorMessage = 'Error sending friend request';
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      }
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const removeFriend = async (friendId) => {
    try {
      const token = await getIdToken();
      await api.delete(`/friends/${friendId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Friend removed successfully', severity: 'success' });
      fetchFriends();
    } catch (error) {
      console.error('Error removing friend:', error);
      setSnackbar({ open: true, message: 'Error removing friend', severity: 'error' });
    }
  };

  const addGroup = async () => {
    try {
      const token = await getIdToken();
      await api.post('/friends/group', { name: newGroupName }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNewGroupName('');
      setSnackbar({ open: true, message: 'Group created successfully!', severity: 'success' });
      fetchGroups();
    } catch (error) {
      console.error('Error creating group:', error);
      setSnackbar({ open: true, message: 'Error creating group', severity: 'error' });
    }
  };

  const removeGroup = async (groupId) => {
    try {
      const token = await getIdToken();
      await api.delete(`/friends/group/${groupId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Group removed successfully!', severity: 'success' });
      fetchGroups();
    } catch (error) {
      console.error('Error removing group:', error);
      setSnackbar({ open: true, message: 'Error removing group', severity: 'error' });
    }
  };

  const handleAcceptFriendRequest = async (friendUid) => {
    try {
      const token = await getIdToken();
      await api.post(`/friends/accept/${friendUid}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Friend request accepted', severity: 'success' });
      fetchPendingFriendRequests();
      fetchFriends();
    } catch (error) {
      console.error('Error accepting friend request:', error);
      setSnackbar({ open: true, message: error.response?.data?.error || 'Error accepting friend request', severity: 'error' });
    }
  };

  const handleRejectFriendRequest = async (friendUid) => {
    try {
      const token = await getIdToken();
      await api.post(`/friends/reject/${friendUid}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSnackbar({ open: true, message: 'Friend request rejected', severity: 'success' });
      fetchPendingFriendRequests();
    } catch (error) {
      console.error('Error rejecting friend request:', error);
      setSnackbar({ open: true, message: error.response?.data?.error || 'Error rejecting friend request', severity: 'error' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleShareInvite = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Join me on Our App!',
        text: 'Check out this awesome app!',
        url: inviteLink,
      }).then(() => {
        setSnackbar({ open: true, message: 'Invite shared successfully!', severity: 'success' });
      }).catch((error) => {
        console.error('Error sharing', error);
      });
    } else {
      navigator.clipboard.writeText(inviteLink);
      setSnackbar({ open: true, message: 'Invite link copied to clipboard!', severity: 'success' });
    }
  };

  if (loading) {
    return (
      <Container component="main" maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="sm">
      <Fade in={true}>
        <StyledPaper elevation={6}>
          <BackButton color="primary" onClick={handleBackClick}>
            <ArrowBackIcon />
          </BackButton>
          <Typography component="h1" variant="h5" gutterBottom>
            Friends and Groups
          </Typography>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            centered 
            sx={{ mb: 3 }}
            variant={isMobile ? "scrollable" : "standard"}
            scrollButtons="auto"
          >
            <Tab label="Friends" />
            <Tab label="Groups" />
            <Tab label="Requests" />
            <Tab label="Add" />
          </Tabs>
          
          <Box width="100%">
            {tabValue === 0 && (
              <Fade in={true}>
                <List>
                  {friends.map((friend) => (
                    <StyledListItem key={friend.uid}>
                      <ListItemAvatar>
                        <StyledAvatar src={friend.photoURL || undefined}>
                          {friend.displayName ? friend.displayName[0].toUpperCase() : 'U'}
                        </StyledAvatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={friend.displayName} 
                        secondary={friend.email}
                      />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => removeFriend(friend.uid)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </StyledListItem>
                  ))}
                  {friends.length === 0 && (
                    <Typography align="center" color="textSecondary">
                      You don't have any friends yet. Start adding some!
                    </Typography>
                  )}
                </List>
              </Fade>
            )}
            
            {tabValue === 1 && (
    <Fade in={true}>
      <Box>
        <List>
          {groups.map((group) => (
            <Card key={group.id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">{group.name}</Typography>
                <Typography color="textSecondary">
                  Members: {group.members.length}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpenGroupManagement(group)}
                >
                  Manage Group
                </Button>
                <Button
                  size="small"
                  color="error"
                  onClick={() => removeGroup(group.id)}
                >
                  Delete Group
                </Button>
                <ExpandMore
                  expand={expandedGroup === group.id}
                  onClick={() => handleExpandClick(group.id)}
                  aria-expanded={expandedGroup === group.id}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </CardActions>
                      <Collapse in={expandedGroup === group.id} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography paragraph>Members:</Typography>
                          <List>
                            {group.members.map((memberId) => (
                              <ListItem key={memberId}>
                                <ListItemText primary={memberId} />
                              </ListItem>
                            ))}
                          </List>
                        </CardContent>
                      </Collapse>
                    </Card>
                  ))}
                </List>
                  <Divider sx={{ my: 2 }} />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="New Group Name"
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<GroupAddIcon />}
                    onClick={addGroup}
                    style={{ marginTop: '1rem' }}
                  >
                    Create Group
                  </Button>
                </Box>
              </Fade>
            )}
            
            {tabValue === 2 && (
              <Fade in={true}>
                <Box>
                  <Typography variant="h6" gutterBottom>Pending Friend Requests</Typography>
                  <List>
                    {pendingFriendRequests.map((request) => (
                      <StyledListItem key={request.uid}>
                        <ListItemAvatar>
                          <StyledAvatar src={request.photoURL || undefined}>
                            {request.displayName ? request.displayName[0].toUpperCase() : 'U'}
                          </StyledAvatar>
                        </ListItemAvatar>
                        <ListItemText 
                          primary={`Request from ${request.displayName}`} 
                          secondary={request.email}
                        />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="accept" onClick={() => handleAcceptFriendRequest(request.uid)}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label="reject" onClick={() => handleRejectFriendRequest(request.uid)}>
                            <CloseIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </StyledListItem>
                    ))}
                    {pendingFriendRequests.length === 0 && (
                      <Typography align="center" color="textSecondary">
                        No pending friend requests.
                      </Typography>
                    )}
                  </List>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" gutterBottom>Sent Friend Requests</Typography>
                  <List>
                    {sentFriendRequests.map((request) => (
                      <StyledListItem key={request.uid}>
                        <ListItemAvatar>
                          <StyledAvatar src={request.photoURL || undefined}>
                            {request.displayName ? request.displayName[0].toUpperCase() : 'U'}
                          </StyledAvatar>
                        </ListItemAvatar>
                        <ListItemText 
                          primary={`Request sent to ${request.displayName}`}
                          secondary={request.email}
                        />
                        <ListItemSecondaryAction>
                          <Chip label="Pending" color="primary" variant="outlined" />
                        </ListItemSecondaryAction>
                      </StyledListItem>
                    ))}
                    {sentFriendRequests.length === 0 && (
                      <Typography align="center" color="textSecondary">
                        You haven't sent any friend requests.
                      </Typography>
                    )}
                  </List>
                </Box>
              </Fade>
            )}
            
            {tabValue === 3 && (
              <Fade in={true}>
                <Box>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="Add Friend by Email"
                    value={newFriendEmail}
                    onChange={(e) => setNewFriendEmail(e.target.value)}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<PersonAddIcon />}
                    onClick={sendFriendRequest}
                    style={{ marginTop: '1rem' }}
                  >
                    Send Friend Request
                  </Button>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" gutterBottom>Invite Friends</Typography>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    startIcon={<EmailIcon />}
                    onClick={handleShareInvite}
                    style={{ marginTop: '1rem' }}
                  >
                    Share Invite Link
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    startIcon={<QrCodeIcon />}
                    onClick={() => setShowQRCode(true)}
                    style={{ marginTop: '1rem' }}
                  >
                    Show QR Code
                  </Button>
                </Box>
              </Fade>
            )}
          </Box>
        </StyledPaper>
      </Fade>

      <GroupManagement
        open={openGroupManagement}
        group={selectedGroup}
        onClose={handleCloseGroupManagement}
      />


      <Dialog open={showQRCode} onClose={() => setShowQRCode(false)}>
        <DialogTitle>Scan QR Code to Join</DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center" p={2}>
            <QRCodeSVG value={inviteLink} size={256} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowQRCode(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Friends;