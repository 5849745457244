import React, { useState, useEffect } from 'react';
import { 
  Button, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, Snackbar, IconButton 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PWAInstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Detect iOS
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);

    // Show prompt for iOS after a delay
    if (isIOSDevice) {
      const timer = setTimeout(() => setShowInstallPrompt(true), 30000); // 30 seconds delay
      return () => clearTimeout(timer);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowInstallPrompt(false);
      });
    }
  };

  const handleClose = () => {
    setShowInstallPrompt(false);
  };

  return (
    <>
      <Dialog
        open={showInstallPrompt}
        onClose={handleClose}
        aria-labelledby="install-dialog-title"
        aria-describedby="install-dialog-description"
      >
        <DialogTitle id="install-dialog-title">{"Install Our App"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="install-dialog-description">
            {isIOS ? 
              "To install our app on your iOS device, tap the share button and then 'Add to Home Screen'." :
              "Install our app for a faster, more responsive experience. You'll be able to use it offline and it won't take up much space."
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Maybe Later
          </Button>
          {!isIOS && (
            <Button onClick={handleInstallClick} color="primary" autoFocus>
              Install
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showInstallPrompt && isIOS}
        message="Install our app for a better experience!"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default PWAInstallPrompt;