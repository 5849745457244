import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { 
  Container, Typography, Grid, Box, Fab, CircularProgress,
  Alert, Snackbar, Paper, TextField, Chip, IconButton, Menu, 
  MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, 
  List, ListItem, ListItemIcon, ListItemText, Checkbox, Button, 
  Toolbar, AppBar, Tabs, Tab, Select, Divider, Pagination
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PublicIcon from '@mui/icons-material/Public';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import EventCard from '../components/EventCard';
import EventListItem from '../components/EventListItem';
import { categories } from '../constants/categories';
import { parseISO, format, isValid, startOfDay, endOfDay, isSameDay, addDays } from 'date-fns';
import { calculateDistance } from '../utils/distanceCalculation';
import { useCachedDistances } from '../hooks/useCachedDistances';
import { getCertificationStyle } from '../utils/certificationUtils';
import LoadingError from '../components/LoadingError';
import CustomizablePulsatingGlowBorder from '../components/PulsatingGlowBorder';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchPreferredCategories, updatePreferredCategories } from '../utils/categoryUtils';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import EventMap from '../components/EventMap'; 
import ThemeSwitcher from '../components/ThemeSwitcher';
import { api } from '../utils/api';
import logo from '../assets/logo.png';
import { useInView } from 'react-intersection-observer';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Timestamp } from 'firebase/firestore';
import PublicEventBrowser from '../components/PublicEventBrowser';  // Ajoutez cet import


const StickyDateHeader = styled(Typography)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  zIndex: 11,
  padding: theme.spacing(2, 0),
}));



const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    flexGrow: 1,
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 200px)',
  width: '100%',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

function Home() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [favoriteHashtags, setFavoriteHashtags] = useState([]);
  const [blacklistedHashtags, setBlacklistedHashtags] = useState([]);
  const [openHashtagDialog, setOpenHashtagDialog] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [userLocation, setUserLocation] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [preferredCategories, setPreferredCategories] = useState([]);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [reportedEvent, setReportedEvent] = useState(null);
  const [viewMode, setViewMode] = useState(() => {
    return localStorage.getItem('viewMode') || 'grid';
  });
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [sortedEvents, setSortedEvents] = useState([]);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [groupedEvents, setGroupedEvents] = useState({});
  const [visibleDays, setVisibleDays] = useState(7);
  const [timeframe, setTimeframe] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 20;

  const navigate = useNavigate();
  const { currentUser, getIdToken } = useAuth();
  const cachedDistances = useCachedDistances(allEvents, userLocation);
  const [mapCenter, setMapCenter] = useState(userLocation);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [locationLoading, setLocationLoading] = useState(true);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '200px',
  });

  const [tabValue, setTabValue] = useState(() => {
    return parseInt(localStorage.getItem('selectedTab') || '0');
  });
  const [selectedHashtags, setSelectedHashtags] = useState(() => {
    return JSON.parse(localStorage.getItem('selectedHashtags') || '[]');
  });
  const [sortBy, setSortBy] = useState(() => {
    return localStorage.getItem('sortBy') || 'dateAsc';
  });

  const handleReportEvent = useCallback((event) => {
    setReportedEvent(event);
    setReportDialogOpen(true);
  }, []);

  const fetchBlockedUsers = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/users/blocked', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        setBlockedUsers(response.data.blockedUsers);
      } else {
        throw new Error('Failed to fetch blocked users');
      }
    } catch (error) {
      console.error('Error fetching blocked users:', error);
      setSnackbar({ open: true, message: "Failed to fetch blocked users. Please try again.", severity: "error" });
    }
  }, [getIdToken]);

  const handleBlock = useCallback(async (creatorId) => {
    try {
      const token = await getIdToken();
      const response = await api.post(`/users/block/${creatorId}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      if (response.status === 200) {
        console.log(`Blocked creator with ID: ${creatorId}`);
        setSnackbar({ open: true, message: "Creator blocked successfully", severity: "success" });
        
        fetchBlockedUsers();
      } else {
        throw new Error('Failed to block user');
      }
    } catch (error) {
      console.error('Error blocking creator:', error);
      setSnackbar({ open: true, message: "Failed to block creator. Please try again.", severity: "error" });
    }
  }, [getIdToken, fetchBlockedUsers]);
  
  const handleViewCreatorProfile = useCallback((creatorId) => {
    navigate(`/profile/${creatorId}`);
  }, [navigate]);

  const getStoredData = () => {
    const storedTab = localStorage.getItem('selectedTab');
    const storedHashtags = JSON.parse(localStorage.getItem('selectedHashtags'));
    return { storedTab, storedHashtags };
  };

  useEffect(() => {
    localStorage.setItem('selectedTab', tabValue.toString());
    localStorage.setItem('selectedHashtags', JSON.stringify(selectedHashtags));
    localStorage.setItem('sortBy', sortBy);
    localStorage.setItem('viewMode', viewMode);
  }, [tabValue, selectedHashtags, sortBy, viewMode]);

  useEffect(() => {
    const { storedTab, storedHashtags } = getStoredData();
    if (storedTab) setTabValue(parseInt(storedTab));
    if (storedHashtags) setSelectedHashtags(storedHashtags);
  }, []);

  const speedDialActions = [
    { icon: <PersonIcon />, name: 'Profile', handleClick: () => navigate('/profile') },
    { icon: <PeopleIcon />, name: 'Friends', handleClick: () => navigate('/friends') },
    { icon: <EventIcon />, name: 'My Events', handleClick: () => navigate('/my-events') },
    { icon: <AddIcon />, name: 'Create Event', handleClick: () => navigate('/create-event') },
  ];

  const handleViewDetails = (event) => {
    navigate(`/event/${event.id}`, { state: { eventData: event } });
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };

  const handleOpenCategoryDialog = () => {
    loadCategories();
    setOpenCategoryDialog(true);
  };

  const loadCategories = useCallback(async () => {
    try {
      const token = await getIdToken();
      const categories = await fetchPreferredCategories(token);
      setPreferredCategories(categories);
    } catch (error) {
      console.error("Error loading categories:", error);
      setSnackbar({ open: true, message: 'Error fetching preferred categories', severity: 'error' });
    }
  }, [getIdToken]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const handleCategoryToggle = async (category) => {
    const updatedCategories = preferredCategories.includes(category)
      ? preferredCategories.filter(c => c !== category)
      : [...preferredCategories, category];
    
    setPreferredCategories(updatedCategories);
    
    try {
      const token = await getIdToken();
      await updatePreferredCategories(token, updatedCategories);
      setSnackbar({ open: true, message: 'Preferred categories updated successfully', severity: 'success' });
    } catch (error) {
      console.error("Error updating categories:", error);
      setSnackbar({ open: true, message: 'Failed to update preferred categories', severity: 'error' });
    }
  };

  const formatEventDate = useMemo(() => (dateString) => {
    if (!dateString) return 'Date not available';
    const date = typeof dateString === 'object' && dateString._seconds
      ? new Date(dateString._seconds * 1000)
      : parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'Invalid date';
  }, []);

  const getDateInMilliseconds = (dateValue) => {
    if (!dateValue) return 0;
    
    if (typeof dateValue === 'object' && dateValue._seconds) {
      return dateValue._seconds * 1000 + (dateValue._nanoseconds / 1000000);
    } else if (typeof dateValue === 'string') {
      const parsedDate = parseISO(dateValue);
      return isValid(parsedDate) ? parsedDate.getTime() : 0;
    } else if (dateValue instanceof Date) {
      return isValid(dateValue) ? dateValue.getTime() : 0;
    }
    return 0;
  };  

  const fetchUserProfile = useCallback(async () => {
    try {
      const token = await getIdToken();
      const response = await api.get('/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPreferredCategories(response.data.preferredCategories || []);
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setSnackbar({ open: true, message: 'Error fetching user profile', severity: 'error' });
    }
  }, [getIdToken]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const getUserLocation = useCallback(() => {
    setLocationLoading(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          setLocationLoading(false);
        },
        (error) => {
          console.error("Error getting user location:", error);
          setSnackbar({ open: true, message: 'Unable to get your location. Please try again.', severity: 'error' });
          setLocationLoading(false);
        }
      );
    } else {
      setSnackbar({ open: true, message: 'Geolocation is not supported by your browser.', severity: 'error' });
      setLocationLoading(false);
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  const fetchEvents = useCallback(async (pageToFetch = page) => {
    if (!currentUser || !userLocation || !hasMore) return;
  
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/events', {
        params: {
          tab: tabValue,
          page: pageToFetch,
          limit: 250,
          latitude: userLocation.latitude,
          longitude: userLocation.longitude,
          radius: 50000,
          timeframe: timeframe
        }
      });
  
      const newEvents = Array.isArray(response.data.events) ? response.data.events : [];
      setAllEvents(prev => {
        const updatedEvents = [...prev];
        newEvents.forEach(event => {
          const index = updatedEvents.findIndex(e => e.id === event.id);
          if (index !== -1) {
            updatedEvents[index] = event;
          } else {
            updatedEvents.push(event);
          }
        });
        return updatedEvents;
      });
      setHasMore(response.data.hasMore);
      setPage(pageToFetch);
    } catch (err) {
      console.error("Error fetching events:", err);
      setError("An error occurred while fetching events. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [currentUser, userLocation, tabValue, hasMore, page, timeframe, api]);

  useEffect(() => {
    if (userLocation) {
      fetchEvents(1);
    }
  }, [fetchEvents, userLocation, tabValue, timeframe]);

  const handleJoinEvent = async (eventId) => {
    if (!currentUser) {
      setSnackbar({ open: true, message: 'Please log in to join events.', severity: 'error' });
      return;
    }
  
    try {
      const response = await api.post(`/events/${eventId}/join`, {
        latitude: userLocation.latitude,
        longitude: userLocation.longitude
      });
  
      if (response.data.success) {
        setSnackbar({ open: true, message: 'Successfully joined the event!', severity: 'success' });
        
        setAllEvents(prevEvents => 
          prevEvents.map(event =>
            event.id === eventId
              ? { ...event, participants: [...(event.participants || []), currentUser.uid] }
              : event
          )
        );
      } else {
        setSnackbar({ open: true, message: response.data.message, severity: 'warning' });
      }
    } catch (error) {
      console.error("Error joining event:", error);
      setSnackbar({ open: true, message: 'Error joining event. Please try again.', severity: 'error' });
    }
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
      localStorage.setItem('viewMode', newMode);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCloseHashtagDialog = () => {
    setOpenHashtagDialog(false);
  };

  const handleAddFavoriteHashtag = (hashtag) => {
    setFavoriteHashtags(prev => [...prev, hashtag]);
  };

  const handleRemoveFavoriteHashtag = (hashtag) => {
    setFavoriteHashtags(prev => prev.filter(h => h !== hashtag));
  };

  const handleAddBlacklistedHashtag = (hashtag) => {
    setBlacklistedHashtags(prev => [...prev, hashtag]);
  };

  const handleRemoveBlacklistedHashtag = (hashtag) => {
    setBlacklistedHashtags(prev => prev.filter(h => h !== hashtag));
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
    setAllEvents([]);
    setHasMore(true);
    fetchEvents(1);
  };

  const handleHashtagClick = (hashtag) => {
    setSelectedHashtags(prevHashtags => {
      const updatedHashtags = prevHashtags.includes(hashtag)
        ? prevHashtags.filter(h => h !== hashtag)
        : [...prevHashtags, hashtag];
      localStorage.setItem('selectedHashtags', JSON.stringify(updatedHashtags));
      return updatedHashtags;
    });
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    localStorage.setItem('sortBy', newSortBy);
    handleSortClose();
    setDisplayedEvents(prevEvents => [...prevEvents]);
  };

  const filterEvents = useCallback(() => {
    const filtered = allEvents.filter(event => {
      if (!event || typeof event !== 'object') return false;
      if (blockedUsers.includes(event.createdBy)) return false;
      if (tabValue === 0 && event.eventType !== 'private') return false;
      if (tabValue === 1 && event.eventType !== 'friends') return false;
      if (tabValue === 2 && event.eventType !== 'public') return false;
      if (searchTerm && event.title && !event.title.toLowerCase().includes(searchTerm.toLowerCase())) return false;
      if (selectedCategories.length > 0 && !selectedCategories.includes(event.eventCategory)) return false;
      if (preferredCategories.length > 0 && event.eventType === 'public' && !preferredCategories.includes(event.eventCategory)) return false;
      if (selectedHashtags.length > 0 && Array.isArray(event.hashtags) && !selectedHashtags.every(tag => event.hashtags.includes(tag))) return false;
      if (favoriteHashtags.length > 0 && Array.isArray(event.hashtags) && !event.hashtags.some(tag => favoriteHashtags.includes(tag))) return false;
      if (Array.isArray(event.hashtags) && Array.isArray(blacklistedHashtags) && event.hashtags.some(tag => blacklistedHashtags.includes(tag))) return false;
      return true;
    });
    setFilteredEvents(filtered);
  }, [allEvents, blockedUsers, tabValue, searchTerm, selectedCategories, preferredCategories, selectedHashtags, favoriteHashtags, blacklistedHashtags]);

  useEffect(() => {
    filterEvents();
  }, [filterEvents]);

  const sortAndGroupEvents = useCallback(() => {
    const sorted = [...filteredEvents].map(event => {
      const cacheKey = `${event.id}-${userLocation?.latitude}-${userLocation?.longitude}`;
      const cachedDistance = cachedDistances[cacheKey];
      return {
        ...event,
        distance: cachedDistance ? cachedDistance.distance : Infinity,
        formattedDistance: cachedDistance ? `${cachedDistance.distance.toFixed(1)} km` : 'N/A'
      };
    }).sort((a, b) => {
      const dateA = getDateInMilliseconds(a.startDate);
      const dateB = getDateInMilliseconds(b.startDate);
      if (isNaN(dateA) || isNaN(dateB)) {
        console.error('Invalid date encountered:', { a: a.startDate, b: b.startDate });
        return 0;
      }
      switch (sortBy) {
        case 'dateDesc': return dateB - dateA;
        case 'dateAsc': return dateA - dateB;
        case 'distanceAsc': return a.distance - b.distance;
        case 'distanceDesc': return b.distance - a.distance;
        case 'popularityDesc': return (b.participants?.length || 0) - (a.participants?.length || 0);
        case 'popularityAsc': return (a.participants?.length || 0) - (b.participants?.length || 0);
        default: return 0;
      }
    });
  
    const grouped = sorted.reduce((acc, event) => {
      const eventDate = new Date(getDateInMilliseconds(event.startDate));
      if (!isValid(eventDate)) {
        console.error('Invalid event date:', event.startDate);
        return acc;
      }
      const dateKey = format(eventDate, 'yyyy-MM-dd');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    }, {});
  
    setGroupedEvents(grouped);
    setSortedEvents(sorted);
  }, [filteredEvents, userLocation, cachedDistances, sortBy, getDateInMilliseconds]);

  useEffect(() => {
    sortAndGroupEvents();
  }, [sortAndGroupEvents]);

  useEffect(() => {
    const sortedDates = Object.keys(groupedEvents).sort();
    const visibleEvents = sortedDates.slice(0, visibleDays).flatMap(date => groupedEvents[date]);
    setDisplayedEvents(visibleEvents);
  }, [groupedEvents, visibleDays]);

  const loadMoreEvents = useCallback(() => {
    setVisibleDays(prevDays => prevDays + 7);
  }, []);

  useEffect(() => {
    if (inView) {
      loadMoreEvents();
    }
  }, [inView, loadMoreEvents]);

  const paginatedEvents = useMemo(() => {
    const startIndex = (currentPage - 1) * eventsPerPage;
    const endIndex = startIndex + eventsPerPage;
    return displayedEvents.slice(startIndex, endIndex);
  }, [displayedEvents, currentPage, eventsPerPage]);

  const totalPages = Math.ceil(displayedEvents.length / eventsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo(0, 0);
  };

  const renderEventsByDay = () => {
    const groupedPaginatedEvents = paginatedEvents.reduce((acc, event) => {
      const eventDate = new Date(getDateInMilliseconds(event.startDate));
      if (!isValid(eventDate)) {
        console.error('Invalid event date:', event.startDate);
        return acc;
      }
      const dateKey = format(eventDate, 'yyyy-MM-dd');
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(event);
      return acc;
    }, {});

    return Object.entries(groupedPaginatedEvents)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, events]) => {
        const eventDate = parseISO(date);
        if (!isValid(eventDate)) {
          console.error('Invalid date in renderEventsByDay:', date);
          return null;
        }
        return (
          <Box key={date} mb={4}>
            <StickyDateHeader variant="h6" gutterBottom>
              {format(eventDate, 'EEEE, MMMM d, yyyy')}
            </StickyDateHeader>
            <Grid container spacing={2}>
              {events.map((event) => (
                <Grid item xs={12} sm={6} md={4} key={event.id}>
                  <CustomizablePulsatingGlowBorder 
                    color1={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.borderColor : '#ccc'} 
                    color2={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType)?.badgeColor : '#eee'}
                  >
                    <EventCard 
                      event={event}
                      certificationStyle={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType) : null}
                      onViewDetails={() => handleViewDetails(event)}
                      onJoin={() => handleJoinEvent(event.id)}
                      onHashtagClick={handleHashtagClick}
                      onReport={handleReportEvent}
                      onBlock={handleBlock}
                      onViewCreatorProfile={handleViewCreatorProfile}
                    />
                  </CustomizablePulsatingGlowBorder>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      })
      .filter(Boolean);
  };

  const sortOptions = [
    { value: 'dateDesc', label: 'Starting Latest' },
    { value: 'dateAsc', label: 'Starting Soonest' },
    { value: 'distanceAsc', label: 'Nearest' },
    { value: 'distanceDesc', label: 'Farthest' },
    { value: 'popularityDesc', label: 'Most Popular' },
    { value: 'popularityAsc', label: 'Least Popular' },
  ];

  return (
    <>
      <AppBar position="static" color="default">
        {tabValue !== 2 && (
          <Toolbar sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {/* Logo container */}
            <Box 
              component="div" 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                minWidth: 'fit-content'
              }}
            >
              <img 
                src={logo} 
                alt="Logo" 
                style={{ 
                  height: '32px', 
                  cursor: 'pointer',
                  marginRight: '8px'
                }} 
              />
            </Box>

            {/* Search and actions container */}
            <Box sx={{ 
              display: 'flex', 
              flexGrow: 1, 
              alignItems: 'center',
              gap: 1
            }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Search>
              
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton color="inherit" onClick={handleOpenCategoryDialog}>
                  <FilterListIcon />
                </IconButton>
                <IconButton color="inherit" onClick={handleSortClick}>
                  <SortIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        )}
        
        <Tabs value={tabValue} onChange={handleTabChange} centered variant="fullWidth">
          <Tab label="Private" />
          <Tab label="Friends" />
          <Tab label="Public" />
        </Tabs>
      </AppBar>      {tabValue === 2 ? (
        <PublicEventBrowser />
      ) : (<>

        
      {userLocation && (
      <Container maxWidth="md" style={{ marginTop: '2rem', marginBottom: '5rem' }}>
        <StyledPaper elevation={3}>
          {selectedCategories.length > 0 && (
            <Box mt={2} mb={2}>
              {selectedCategories.map((category) => (
                <Chip
                  key={category}
                  label={categories.find(c => c.value === category)?.label}
                  onDelete={() => handleCategoryToggle(category)}
                  color="primary"
                  style={{ margin: '0 4px 4px 0' }}
                />
              ))}
            </Box>
          )}

          {selectedHashtags.length > 0 && (
            <Box mt={2} mb={2}>
              {selectedHashtags.map((tag) => (
                <Chip
                key={tag}
                label={tag}
                onDelete={() => handleHashtagClick(tag)}
                style={{ margin: '0 4px 4px 0' }}
              />
            ))}
          </Box>
        )}
      </StyledPaper>

      {tabValue === 2 && (
        <Select
          value={timeframe}
          onChange={(e) => setTimeframe(e.target.value)}
          style={{ marginBottom: '1rem' }}
        >
          <MenuItem value={0}>This week</MenuItem>
          <MenuItem value={1}>Next week</MenuItem>
          <MenuItem value={2}>Next 2 weeks</MenuItem>
          <MenuItem value={3}>Next month</MenuItem>
        </Select>
      )}

      <LoadingError loading={loading} error={error}>
        {Object.keys(groupedEvents).length === 0 ? (
          <Box textAlign="center" my={4}>
            <Typography variant="h6" gutterBottom>
              No events found
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              {tabValue === 0 ? "You don't have any private events." :
              tabValue === 1 ? "Your friends haven't created any events." :
              "There are no public events matching your criteria."}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => navigate('/create-event')}
            >Create an Event
            </Button>
          </Box>
        ) : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="subtitle1">
                Showing {paginatedEvents.length} of {displayedEvents.length}
                <Select
                  value={sortBy}
                  onChange={(e) => handleSortChange(e.target.value)}
                  sx={{
                    ml: 1,
                    minWidth: 120,
                    height: '24px',
                    '& .MuiSelect-select': {
                      py: 0,
                    },
                  }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                >
                  {sortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Typography>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewModeChange}
                aria-label="view mode"
              >
                <ToggleButton value="grid" aria-label="grid view">
                  <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton value="list" aria-label="list view">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="map" aria-label="map view">
                  <PublicIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            {viewMode === 'grid' && renderEventsByDay()}

            {viewMode === 'list' && (
              <List>
                {Object.entries(groupedEvents)
                  .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
                  .slice(0, visibleDays)
                  .map(([date, events]) => (
                    <React.Fragment key={date}>
                      <StickyDateHeader variant="h6" gutterBottom>
                        <Typography variant="h6">
                          {format(new Date(date), 'EEEE, MMMM d, yyyy')}
                        </Typography>
                      </StickyDateHeader>
                      {events.map((event) => (
                        <ListItem key={event.id} disablePadding>
                          <EventListItem 
                            event={event}
                            certificationStyle={event.creatorIsCertified ? getCertificationStyle(event.creatorCertificationType) : null}
                            onViewDetails={() => handleViewDetails(event)}
                            onJoin={() => handleJoinEvent(event.id)}
                            onHashtagClick={handleHashtagClick}
                            onReport={handleReportEvent}
                            onBlockCreator={handleBlock}
                            onViewCreatorProfile={handleViewCreatorProfile}
                          />
                        </ListItem>
                      ))}
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
            )}

            {viewMode === 'map' && (
              <MapWrapper>
                <EventMap 
                  events={paginatedEvents}
                  userLocation={userLocation}
                  onViewDetails={handleViewDetails}
                  formatEventDate={formatEventDate}
                  onReport={handleReportEvent}
                  onBlockCreator={handleBlock}
                  onViewCreatorProfile={handleViewCreatorProfile}
                />
              </MapWrapper>
            )}

            <Box display="flex" justifyContent="center" mt={4} mb={4}>
              <Pagination 
                count={totalPages} 
                page={currentPage} 
                onChange={handlePageChange} 
                color="primary" 
              />
            </Box>

            <Box ref={ref} height="20px" />
          </>
        )}
      </LoadingError>

      <SpeedDial
        ariaLabel="Navigation SpeedDial"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {speedDialActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.handleClick}
          />
        ))}
      </SpeedDial>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Menu
        anchorEl={sortAnchorEl}
        open={Boolean(sortAnchorEl)}
        onClose={handleSortClose}
      >
        {sortOptions.map((option) => (
          <MenuItem key={option.value} onClick={() => handleSortChange(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>

      <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
        <DialogTitle>Manage Preferred Categories</DialogTitle>
        <DialogContent>
          <List>
            {categories.map((category) => (
              <ListItem key={category.value} dense button onClick={() => handleCategoryToggle(category.value)}>
                <ListItemIcon>
                  <category.icon />
                </ListItemIcon>
                <ListItemText primary={category.label} />
                <Checkbox
                  edge="end"
                  checked={preferredCategories.includes(category.value)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCategoryDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openHashtagDialog} onClose={handleCloseHashtagDialog}>
        <DialogTitle>Manage Hashtags</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Favorite Hashtags</Typography>
          <List>
            {favoriteHashtags.map((hashtag) => (
              <ListItem key={hashtag}>
                <ListItemText primary={hashtag} />
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFavoriteHashtag(hashtag)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <TextField
            label="Add Favorite Hashtag"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddFavoriteHashtag(e.target.value);
                e.target.value = '';
              }
            }}
          />

          <Typography variant="h6" style={{ marginTop: '1rem' }}>Blacklisted Hashtags</Typography>
          <List>
            {blacklistedHashtags.map((hashtag) => (
              <ListItem key={hashtag}>
                <ListItemText primary={hashtag} />
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveBlacklistedHashtag(hashtag)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <TextField
            label="Add Blacklisted Hashtag"
            variant="outlined"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddBlacklistedHashtag(e.target.value);
                e.target.value = '';
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHashtagDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    )}</>)}
  </>
  
);
}

export default Home;