import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { checkAndAwardBadges } from '../utils/badgeUtils';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function generateUniqueUsername(baseUsername) {
    let username = baseUsername;
    let counter = 1;
    let isUnique = false;

    while (!isUnique) {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        isUnique = true;
      } else {
        username = `${baseUsername}${counter}`;
        counter++;
      }
    }

    return username;
  }

  async function createOrUpdateUserProfile(user) {
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);
  
    if (!userSnap.exists()) {
      // Générer un username unique
      const baseUsername = `user${Math.floor(Math.random() * 1000000)}`;
      const uniqueUsername = await generateUniqueUsername(baseUsername);
      
      // Création d'un nouveau profil utilisateur
      await setDoc(userRef, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || user.email.split('@')[0],
        photoURL: user.photoURL || '',
        createdAt: new Date().toISOString(),
        username: uniqueUsername,
        isCertified: false,
        certificationType: 'none',
        certifiedUntil: null,
        badges: [],
      });
      console.log("Nouveau profil utilisateur créé avec les nouveaux champs");
    } else {
      // Mise à jour d'un profil existant
      const updateData = {
        email: user.email,
        displayName: user.displayName || userSnap.data().displayName,
        photoURL: user.photoURL || userSnap.data().photoURL,
        lastLoginAt: new Date().toISOString(),
      };
  
      // Assurez-vous que les nouveaux champs existent
      if (!userSnap.data().hasOwnProperty('username')) {
        updateData.username = await generateUniqueUsername(`user${Math.floor(Math.random() * 1000000)}`);
      }
      if (!userSnap.data().hasOwnProperty('isCertified')) {
        updateData.isCertified = false;
      }
      if (!userSnap.data().hasOwnProperty('certificationType')) {
        updateData.certificationType = 'none';
      }
      if (!userSnap.data().hasOwnProperty('certifiedUntil')) {
        updateData.certifiedUntil = null;
      }
  
      await updateDoc(userRef, updateData);
      console.log("Profil utilisateur mis à jour avec les nouveaux champs");
    }
  }

  async function signup(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await createOrUpdateUserProfile(userCredential.user);
      await checkAndAwardBadges(userCredential.user.uid);
      return userCredential;
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      throw error;
    }
  }
  
  async function login(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await createOrUpdateUserProfile(userCredential.user);
      return userCredential;
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      throw error;
    }
  }

  function logout() {
    return signOut(auth);
  }

  async function loginWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      await createOrUpdateUserProfile(userCredential.user);
      await checkAndAwardBadges(userCredential.user.uid);
      return userCredential;
    } catch (error) {
      console.error("Erreur lors de la connexion avec Google:", error);
      throw error;
    }
  }

  async function getIdToken(forceRefresh = false) {
    if (currentUser) {
      try {
        return await currentUser.getIdToken(forceRefresh);
      } catch (error) {
        console.error("Erreur lors de l'obtention du token ID:", error);
        throw error;
      }
    }
    return null;
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await createOrUpdateUserProfile(user);
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signup,
    login,
    logout,
    loginWithGoogle,
    getIdToken
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}