import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { 
  Container, Typography, Button, Box, Grid, Chip, IconButton,
  Dialog, DialogContent, Snackbar, Alert, CircularProgress, 
  Avatar, Paper, Fade, Zoom, useMediaQuery, useTheme, Card, CardContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  AccessTime, Room, People, Share as ShareIcon, ArrowBack, 
  Category, EventAvailable, Visibility, Close
} from '@mui/icons-material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { parseISO, format, isValid } from 'date-fns';
import L from 'leaflet';
import { api } from '../utils/api';
import SafeDescriptionComponent from '../components/SafeDescriptionComponent';

// Leaflet icon setup
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
}));

const EventImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '300px',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  borderRadius: '8px 8px 0 0',
  backgroundColor: theme.palette.grey[900],
  cursor: 'pointer',
}));

const EventImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  '& .MuiChip-icon': {
    color: 'inherit',
  },
}));

const MapWrapper = styled(Box)(({ theme }) => ({
  height: '300px',
  [theme.breakpoints.down('sm')]: {
    height: '200px',
  },
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  marginTop: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  textTransform: 'none',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
    padding: '6px 12px',
  },
}));

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    maxWidth: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: theme.spacing(2),
  color: 'white',
}));

const InfoCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const HashtagContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

function PublicEventDetails() {
  const location = useLocation();
  const { eventId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [event, setEvent] = useState(location.state?.eventData || null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [loading, setLoading] = useState(!event);
  const [error, setError] = useState(null);

  const fetchEventDetails = useCallback(async () => {
    if (event) return;

    try {
      setLoading(true);
      const response = await api.get(`/public-events/${eventId}`);
      setEvent(response.data);
    } catch (err) {
      console.error("Error fetching event details:", err);
      setError("Failed to fetch event details. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [eventId, event]);

  useEffect(() => {
    fetchEventDetails();
  }, [fetchEventDetails]);

  const openInGoogleMaps = useCallback(() => {
    if (event && event.coordinates) {
      const { latitude, longitude } = event.coordinates;
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(url, '_blank');
    }
  }, [event]);

  const formatEventDate = useMemo(() => (dateString) => {
    if (!dateString) return 'Date not available';
    const date = typeof dateString === 'object' && dateString._seconds
      ? new Date(dateString._seconds * 1000)
      : parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'Invalid date';
  }, []);

  if (loading) {
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !event) {
    return (
      <Container maxWidth="md">
        <StyledPaper>
          <Typography variant="h6" color="error">{error || 'Event not found'}</Typography>
          <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </StyledPaper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Fade in={true} timeout={500}>
        <StyledPaper elevation={6}>
          <IconButton 
            onClick={() => navigate(-1)} 
            sx={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }}
          >
            <ArrowBack />
          </IconButton>
          
          <EventImageContainer onClick={() => setOpenImageDialog(true)}>
            <EventImage src={event.imageUrl || '/placeholder-image.jpg'} alt={event.title} />
          </EventImageContainer>
          
          <Box p={isMobile ? 2 : 3}>
            <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>{event.title}</Typography>
            
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar src={event.creatorPhotoURL} alt={event.username}>
                {event.username ? event.username[0].toUpperCase() : 'A'}
              </Avatar>
              <Typography variant="subtitle1" ml={1}>
                Created by {event.username || 'Anonymous'}
              </Typography>
            </Box>

            <InfoCard>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <InfoChip icon={<EventAvailable />} label={formatEventDate(event.startDate)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoChip icon={<Room />} label={event.address} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoChip icon={<Category />} label={event.eventCategory} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoChip icon={<People />} label={`${event.participantCount || 0} participants`} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InfoChip icon={<Visibility />} label={`${event.viewCount || 0} views`} />
                  </Grid>
                </Grid>
              </CardContent>
            </InfoCard>

            <Typography variant="body1" mt={2}>
              <SafeDescriptionComponent description={event.description} />
            </Typography>

            <HashtagContainer>
              {event.hashtags && event.hashtags.map((tag, index) => (
                <InfoChip key={index} label={`${tag}`} />
              ))}
            </HashtagContainer>

            <Box my={2} display="flex" justifyContent="space-between" flexWrap="wrap">
              <ActionButton
                variant="contained"
                color="primary"
                onClick={() => navigate('/login')}
              >
                Log in to Join Event
              </ActionButton>
              <ActionButton
                variant="outlined"
                color="primary"
                startIcon={<ShareIcon />}
                onClick={() => {/* Implement share functionality */}}
              >
                Share
              </ActionButton>
            </Box>

            {event.coordinates && (
              <MapWrapper>
                <MapContainer 
                  center={[event.coordinates.latitude, event.coordinates.longitude]} 
                  zoom={13} 
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker 
                    position={[event.coordinates.latitude, event.coordinates.longitude]}
                    eventHandlers={{
                      click: openInGoogleMaps,
                    }}
                  >
                    <Popup>
                      <Typography variant="body2">{event.title}</Typography>
                      <Typography variant="body2">{event.address}</Typography>
                      <Button 
                        size="small" 
                        onClick={openInGoogleMaps}
                        sx={{ mt: 1 }}
                      >
                        Open in Google Maps
                      </Button>
                    </Popup>
                  </Marker>
                </MapContainer>
              </MapWrapper>
            )}
          </Box>
        </StyledPaper>
      </Fade>

      <FullScreenDialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        TransitionComponent={Zoom}
      >
        <DialogContent sx={{ p: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <img 
            src={event.imageUrl || '/placeholder-image.jpg'} 
            alt={event.title} 
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
          />
          <CloseButton onClick={() => setOpenImageDialog(false)}>
            <Close />
          </CloseButton>
        </DialogContent>
      </FullScreenDialog>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PublicEventDetails;