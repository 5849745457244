import { api } from './api';

const LOCAL_STORAGE_KEY = 'preferredCategories';

export const fetchPreferredCategories = async (token) => {
  try {
    // Récupérer d'abord du localStorage
    const localCategories = getLocalPreferredCategories();
    if (localCategories.length > 0) {
      return localCategories;
    }

    // Si le localStorage est vide, récupérer du serveur
    const response = await api.get('/profile', {
      headers: { Authorization: `Bearer ${token}` }
    });
    const serverCategories = response.data.preferredCategories || [];
    
    // Mettre à jour le localStorage avec les données du serveur
    setLocalPreferredCategories(serverCategories);
    
    return serverCategories;
  } catch (error) {
    console.error("Error fetching preferred categories:", error);
    throw error;
  }
};

export const updatePreferredCategories = async (token, categories) => {
  try {
    // Mettre à jour le localStorage immédiatement
    setLocalPreferredCategories(categories);
    
    // Puis mettre à jour le serveur
    await api.put('/profile/categories', { categories }, {
      headers: { Authorization: `Bearer ${token}` }
    });
  } catch (error) {
    console.error("Error updating preferred categories:", error);
    throw error;
  }
};

export const getLocalPreferredCategories = () => {
  const categories = localStorage.getItem(LOCAL_STORAGE_KEY);
  return categories ? JSON.parse(categories) : [];
};

export const setLocalPreferredCategories = (categories) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(categories));
};

export const toggleLocalPreferredCategory = (category) => {
  const categories = getLocalPreferredCategories();
  const updatedCategories = categories.includes(category)
    ? categories.filter(c => c !== category)
    : [...categories, category];
  setLocalPreferredCategories(updatedCategories);
  return updatedCategories;
};