// src/components/ThemeSwitcher.js
import React from 'react';
import { IconButton, Tooltip, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { useTheme } from '../contexts/ThemeContext';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  transition: theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'scale(1.1)',
  },
}));

const ThemeSwitcher = () => {
  const { currentTheme, toggleTheme } = useTheme();

  const handleThemeChange = () => {
    const themes = ['midnightOasis', 'professionalLightTheme', 'twilightSerenity'];
    const nextThemeIndex = (themes.indexOf(currentTheme) + 1) % themes.length;
    toggleTheme(themes[nextThemeIndex]);
  };

  const getThemeIcon = () => {
    switch (currentTheme) {
      case 'midnightOasis':
        return <Brightness4Icon />;
      case 'professionalLightTheme':
        return <Brightness7Icon />;
      case 'twilightSerenity':
        return <NightsStayIcon />;
      default:
        return <Brightness4Icon />;
    }
  };

  return (
    <Tooltip
      title={`Switch to ${currentTheme === 'professionalLightTheme' ? 'dark' : 'light'} theme`}
      TransitionComponent={Zoom}
    >
      <StyledIconButton onClick={handleThemeChange} color="inherit">
        {getThemeIcon()}
      </StyledIconButton>
    </Tooltip>
  );
};

export default ThemeSwitcher;