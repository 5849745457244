import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, Circle, LayerGroup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Typography, Button, Box, useMediaQuery, useTheme, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MapIcon from '@mui/icons-material/Map';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import NightlifeIcon from '@mui/icons-material/Nightlife';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import StorefrontIcon from '@mui/icons-material/Storefront';
import GroupsIcon from '@mui/icons-material/Groups';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { parseISO, format, isValid, addHours, isBefore } from 'date-fns';
import EventOptionsMenu from './EventOptionsMenu';
import ReportDialog from '../components/ReportDialog';

const StyledPopup = styled(Popup)(({ theme }) => ({
  '& .leaflet-popup-content-wrapper': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    maxWidth: '300px',
  },
  '& .leaflet-popup-tip': {
    backgroundColor: theme.palette.background.paper,
  },
}));

const StyledButton = styled(Button)(({ theme, isMobile }) => ({
  margin: theme.spacing(0.5, 0),
  padding: isMobile ? theme.spacing(0.5, 1) : theme.spacing(1, 2),
  fontSize: isMobile ? '0.7rem' : '0.8rem',
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
  '& > svg': {
    marginRight: theme.spacing(0.5),
    fontSize: '1rem',
  },
}));

const userIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const getCategoryIcon = (category) => {
  switch (category) {
    case 'culture': return <TheaterComedyIcon />;
    case 'sports': return <SportsSoccerIcon />;
    case 'family': return <FamilyRestroomIcon />;
    case 'nightlife': return <NightlifeIcon />;
    case 'professional': return <BusinessCenterIcon />;
    case 'markets': return <StorefrontIcon />;
    case 'social': return <GroupsIcon />;
    case 'education': return <SchoolIcon />;
    case 'tech': return <ComputerIcon />;
    case 'wellness': return <FitnessCenterIcon />;
    default: return <MoreHorizIcon />;
  }
};

const getCategoryColor = (category) => {
  switch (category) {
    case 'culture': return 'violet';
    case 'sports': return 'green';
    case 'family': return 'orange';
    case 'nightlife': return 'blue';
    case 'professional': return 'gray';
    case 'markets': return 'yellow';
    case 'social': return 'pink';
    case 'education': return 'red';
    case 'tech': return 'cyan';
    case 'wellness': return 'gold';
    default: return 'black';
  }
};

const createEventIcon = (category) => {
  const color = getCategoryColor(category);
  return new L.Icon({
    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`,
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });
};

function useMapView({ center, zoom, events, userLocation }) {
  const map = useMap();

  useEffect(() => {
    if (userLocation && events.length > 0) {
      const bounds = L.latLngBounds([
        [userLocation.latitude, userLocation.longitude],
        ...events.map(e => [e.coordinates.latitude, e.coordinates.longitude])
      ]);
      map.fitBounds(bounds);
    } else if (center) {
      map.setView(center, zoom);
    } else if (events.length > 0) {
      const bounds = L.latLngBounds(events.map(e => [e.coordinates.latitude, e.coordinates.longitude]));
      map.fitBounds(bounds);
    }
  }, [map, center, zoom, events, userLocation]);

  useMapEvents({
    resize: () => {
      map.invalidateSize();
    },
  });

  return null;
}

const EventMap = ({ events, userLocation, onViewDetails, onJoin, onReport, onBlockCreator, onViewCreatorProfile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const validEvents = useMemo(() => events.filter(event => 
    event.coordinates && 
    typeof event.coordinates.latitude === 'number' && 
    typeof event.coordinates.longitude === 'number'
  ), [events]);

  const mapCenter = userLocation
    ? [userLocation.latitude, userLocation.longitude]
    : validEvents.length > 0
      ? [validEvents[0].coordinates.latitude, validEvents[0].coordinates.longitude]
      : [0, 0];

  const openGoogleMaps = useCallback((lat, lng) => {
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank');
  }, []);

  const formatEventDate = useCallback((dateString) => {
    if (!dateString) return 'Date not available';
    const date = typeof dateString === 'object' && dateString._seconds
      ? new Date(dateString._seconds * 1000)
      : parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'Invalid date';
  }, []);

  const isEventSoon = useCallback((startDate) => {
    const now = new Date();
    const eventDate = new Date(startDate);
    const in24Hours = addHours(now, 24);
    return isBefore(eventDate, in24Hours) && isBefore(now, eventDate);
  }, []);

  const handleReport = useCallback((event) => {
    setSelectedEvent(event);
    setOpenReportDialog(true);
  }, []);

  const handleCloseReport = useCallback(() => {
    setOpenReportDialog(false);
    setSelectedEvent(null);
  }, []);

  const renderEventMarker = useCallback((event) => {
    const isSoon = isEventSoon(event.startDate);
    const eventIcon = createEventIcon(event.eventCategory);

    return (
      <Marker 
        key={event.id} 
        position={[event.coordinates.latitude, event.coordinates.longitude]}
        icon={eventIcon}
      >
        <StyledPopup>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <CategoryIcon>
              {getCategoryIcon(event.eventCategory)}
              <Typography variant="caption">{event.eventCategory}</Typography>
            </CategoryIcon>
            <EventOptionsMenu
              onReport={() => handleReport(event)}
              onBlock={() => onBlockCreator(event.createdBy)}
              onViewProfile={() => onViewCreatorProfile(event.createdBy)}
            />
          </Box>
          <Typography variant="subtitle2" noWrap>{event.title}</Typography>
          <Typography variant="caption" display="block" noWrap>{event.address}</Typography>
          <Box display="flex" alignItems="center" mt={0.5} mb={0.5}>
            <AccessTimeIcon fontSize="small" style={{ marginRight: 4 }} />
            <Typography variant="caption">{formatEventDate(event.startDate)}</Typography>
          </Box>
          {isSoon && (
            <Chip
              icon={<EventIcon />}
              label="Starting soon"
              color="secondary"
              size="small"
              style={{ marginBottom: 8 }}
            />
          )}
          <Box mt={0.5}>
            <StyledButton 
              size="small" 
              variant="outlined" 
              onClick={() => onJoin(event.id)}
              fullWidth
              isMobile={isMobile}
            >
              Join
            </StyledButton>
            <StyledButton 
              size="small" 
              variant="contained" 
              onClick={() => onViewDetails(event)}
              fullWidth
              isMobile={isMobile}
            >
              Details
            </StyledButton>
            <StyledButton
              size="small"
              variant="outlined"
              startIcon={<MapIcon />}
              onClick={() => openGoogleMaps(event.coordinates.latitude, event.coordinates.longitude)}
              fullWidth
              isMobile={isMobile}
            >
              Google Maps
            </StyledButton>
          </Box>
        </StyledPopup>
      </Marker>
    );
  }, [formatEventDate, handleReport, isMobile, isEventSoon, onBlockCreator, onJoin, onViewCreatorProfile, onViewDetails, openGoogleMaps]);

  return (
    <>
      <MapContainer center={mapCenter} zoom={13} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <useMapView center={mapCenter} zoom={13} events={validEvents} userLocation={userLocation} />
        
        {userLocation && (
          <LayerGroup>
            <Marker 
              position={[userLocation.latitude, userLocation.longitude]}
              icon={userIcon}
            >
              <Popup>
                <Typography variant="body2">Your location</Typography>
              </Popup>
            </Marker>
            <Circle 
              center={[userLocation.latitude, userLocation.longitude]}
              radius={10000}
              pathOptions={{ fillColor: 'blue', fillOpacity: 0.1, color: 'blue', weight: 1 }}
            />
          </LayerGroup>
        )}

        <LayerGroup>
          {validEvents.map(renderEventMarker)}
        </LayerGroup>
      </MapContainer>

      {selectedEvent && (
        <ReportDialog
          open={openReportDialog}
          onClose={handleCloseReport}
          eventId={selectedEvent.id}
          latitude={selectedEvent.coordinates?.latitude}
          longitude={selectedEvent.coordinates?.longitude}
        />
      )}
    </>
  );
};

export default EventMap;