import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://hubevent.app/api',
});

let interceptorId;

export const setupInterceptors = (currentUser, getIdToken) => {
  // Remove existing interceptor if there is one
  if (interceptorId !== undefined) {
    api.interceptors.request.eject(interceptorId);
  }

  // Add a new interceptor
  interceptorId = api.interceptors.request.use(
    async (config) => {
      if (currentUser) {
        try {
          const token = await getIdToken();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
          console.error("Erreur lors de l'obtention du token:", error);
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const removeInterceptors = () => {
  if (interceptorId !== undefined) {
    api.interceptors.request.eject(interceptorId);
    interceptorId = undefined;
  }
};

export { api };