import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  background: 'rgba(30, 30, 30, 0.7)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

function TermsOfService() {
  return (
    <Container component="main" maxWidth="md">
      <StyledPaper elevation={6}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Terms of Service
        </Typography>
        
        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography paragraph>
            By accessing or using the Out Tonight application ("the App"), you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the App.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            2. Disclaimer of Liability
          </Typography>
          <Typography paragraph>
            The App and its creators, operators, and affiliates are not responsible for any damages, losses, or consequences that may result from the use of the App or attendance at events organized through the App. Users access the App and attend events at their own risk.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            3. User Responsibilities
          </Typography>
          <Typography paragraph>
            Users are solely responsible for their actions and content posted on the App. Users must comply with all applicable local, state, national, and international laws and regulations while using the App. The App does not assume any responsibility for ensuring users' compliance with laws.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            4. Content Removal and Account Termination
          </Typography>
          <Typography paragraph>
            The App reserves the right, at its sole discretion, to remove any content, including but not limited to events, comments, or user profiles, without prior notice or explanation. The App also reserves the right to suspend or terminate user accounts at any time, for any reason, without providing justification or explanation.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            5. No Warranty
          </Typography>
          <Typography paragraph>
            The App is provided "as is" and "as available" without any warranties of any kind, express or implied. We do not guarantee the accuracy, completeness, or usefulness of any information on the App.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            6. Limitation of Liability
          </Typography>
          <Typography paragraph>
            To the fullest extent permitted by applicable law, the App and its creators, operators, and affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your access to or use of or inability to access or use the App.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            7. Changes to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify or replace these Terms at any time. It is your responsibility to check the Terms periodically for changes. Your continued use of the App following the posting of any changes to the Terms constitutes acceptance of those changes.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            8. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about these Terms, please contact us at [your contact email].
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            9. Data Protection and Privacy
          </Typography>
          <Typography paragraph>
            We collect and process personal data in accordance with our Privacy Policy, which is incorporated into these Terms by reference. By using the App, you consent to such processing and you warrant that all data provided by you is accurate.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            10. Intellectual Property Rights
          </Typography>
          <Typography paragraph>
            The App and its original content, features, and functionality are owned by [Your Company Name] and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            11. User-Generated Content
          </Typography>
          <Typography paragraph>
            By posting content on the App, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the App. You retain any and all of your rights to any content you submit, post or display on or through the App and you are responsible for protecting those rights.
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            12. Dispute Resolution
          </Typography>
          <Typography paragraph>
            Any disputes arising out of or related to these Terms or the App shall be governed by the laws of [Your Jurisdiction], without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located within [Your Jurisdiction].
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography variant="h6" gutterBottom>
            13. Severability
          </Typography>
          <Typography paragraph>
            If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
          </Typography>
        </Box>
      </StyledPaper>
    </Container>
  );
}

export default TermsOfService;