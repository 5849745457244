import React, { useCallback, useMemo, useState } from 'react';
import { 
  Box, Typography, IconButton, Chip, 
  styled, useTheme, alpha, Tooltip
} from '@mui/material';
import { motion } from 'framer-motion';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VerifiedIcon from '@mui/icons-material/Verified';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventOptionsMenu from './EventOptionsMenu';
import ReportDialog from '../components/ReportDialog';
import { fr, enUS } from 'date-fns/locale';
import { parseISO, format, isValid, isSameDay } from 'date-fns';
import { categories } from '../constants/categories';

const StyledListItem = styled(motion.div)(({ theme, isCertified, certificationColor }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  boxShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.1)}`,
  marginBottom: theme.spacing(1),
  width: '100%',
  position: 'relative',
  transition: 'background-color 0.3s ease, transform 0.2s ease',
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.95),
    transform: 'scale(1.005)',
  },
  ...(isCertified && {
    boxShadow: `0 0 5px ${alpha(certificationColor, 0.5)}`,
    border: `1px solid ${certificationColor}`,
  }),
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  padding: theme.spacing(1.5, 2),
  backgroundColor: alpha(theme.palette.background.paper, 0.95),
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const TitleWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexGrow: 1,
  marginRight: theme.spacing(1),
  overflow: 'hidden',
  maxWidth: 'calc(100% - 100px)',
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  lineHeight: 1.2,
  fontWeight: 500,
  marginRight: theme.spacing(1),
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  wordBreak: 'break-word',
}));

const BadgesContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
});

const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  width: '80px',
  height: '80px',
  flexShrink: 0,
  marginRight: theme.spacing(2),
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
}));

const GradientFallback = styled(Box)(({ theme, categoryColor }) => ({
  width: '100%',
  height: '100%',
  background: `linear-gradient(135deg, 
    ${alpha(categoryColor || theme.palette.primary.main, 0.8)} 0%, 
    ${alpha(categoryColor || theme.palette.primary.dark, 0.6)} 100%)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at 30% 30%, 
      ${alpha(theme.palette.background.paper, 0.1)} 0%,
      transparent 60%)`,
  },
}));

const EventImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'opacity 0.3s ease',
});

const CategoryIconPlaceholder = styled(Box)(({ theme }) => ({
  opacity: 0.5,
  transform: 'scale(1.5)',
  color: alpha(theme.palette.common.white, 0.8),
}));

const CategoryIcon = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  marginRight: theme.spacing(1),
  padding: theme.spacing(0.75),
  flexShrink: 0,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  marginBottom: theme.spacing(0.5),
  '& svg': {
    fontSize: '0.9rem',
    marginRight: theme.spacing(0.5),
  },
}));

const OptionsMenuWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexShrink: 0,
});

const ArrowIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '65%',
  right: theme.spacing(1),
  transform: 'translateY(-50%)',
}));

const truncate = (str, maxLength) => {
  if (!str) return '';
  if (str.length <= maxLength) return str;
  const minLength = Math.min(maxLength, str.length);
  const truncated = str.substr(0, minLength - 1);
  return `${truncated}…`;
};

const EventListItem = React.memo(({ 
  event, 
  onViewDetails, 
  onHashtagClick, 
  certificationStyle, 
  onBlockCreator, 
  onViewCreatorProfile,
  userLocale = 'fr'
}) => {
  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [imageError, setImageError] = useState(false);
  const theme = useTheme();
  
  const isCertified = event.creatorIsCertified && certificationStyle;
  const certificationColor = isCertified ? certificationStyle.borderColor : 'transparent';

  const getCategoryColor = useCallback((category) => {
    const categoryData = categories.find(c => c.value === category);
    return categoryData?.color || theme.palette.primary.main;
  }, [theme.palette.primary.main]);

  const formatEventDate = useMemo(() => (startDate, endDate) => {
    if (!startDate) return 'Date non disponible';
  
    const start = typeof startDate === 'object' && startDate._seconds
      ? new Date(startDate._seconds * 1000)
      : parseISO(startDate);
  
    if (!isValid(start)) return 'Date invalide';
  
    const end = endDate ? (typeof endDate === 'object' && endDate._seconds
      ? new Date(endDate._seconds * 1000)
      : parseISO(endDate)) : null;
  
    const locale = userLocale === 'fr' ? fr : enUS;
    const formatTime = (date) => format(date, 'HH:mm', { locale });
  
    if (!end || isSameDay(start, end)) {
      return formatTime(start) + (end ? ` - ${formatTime(end)}` : '');
    } else {
      return `${formatTime(start)} - ${formatTime(end)}`;
    }
  }, [userLocale]);

  const handleClick = useCallback(() => {
    if (!openReportDialog) {
      onViewDetails(event);
    }
  }, [event, onViewDetails, openReportDialog]);

  const handleOptionsClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleImageError = useCallback(() => {
    setImageError(true);
  }, []);

  const handleReport = useCallback(() => setOpenReportDialog(true), []);
  const handleCloseReport = useCallback(() => setOpenReportDialog(false), []);

  const handleHashtagClick = useCallback((tag) => (e) => {
    e.stopPropagation();
    onHashtagClick(tag);
  }, [onHashtagClick]);

  const getCategoryIcon = useCallback((category) => {
    const categoryData = categories.find(c => c.value === category);
    return categoryData?.icon ? React.createElement(categoryData.icon, { fontSize: "small" }) : null;
  }, []);

  const renderCertificationBadge = useCallback(() => {
    if (!isCertified) return null;
    
    return (
      <Tooltip title={`Certified ${event.creatorCertification === 'personal' ? 'User' : 'Organization'}`}>
        <IconButton size="small" sx={{ 
          color: certificationColor, 
          padding: '2px',
          marginLeft: '2px',
          '&:hover': { backgroundColor: 'transparent' }
        }}>
          {certificationStyle.icon === 'verified' ? <VerifiedIcon fontSize="small" /> : <VerifiedUserIcon fontSize="small" />}
        </IconButton>
      </Tooltip>
    );
  }, [isCertified, certificationColor, certificationStyle, event.creatorCertification]);

  const categoryColor = getCategoryColor(event.eventCategory);
  const CategoryIconComponent = categories.find(c => c.value === event.eventCategory)?.icon;

  return (
    <>
      <StyledListItem
        onClick={handleClick}
        isCertified={isCertified}
        certificationColor={certificationColor}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.2 }}
      >
        <HeaderContainer>
          <TitleWrapper>
            <CategoryIcon size="small">
              {getCategoryIcon(event.eventCategory)}
            </CategoryIcon>
            <Box sx={{ overflow: 'hidden' }}>
              <EventTitle>
                {event.title}
                {renderCertificationBadge()}
              </EventTitle>
            </Box>
          </TitleWrapper>
          <OptionsMenuWrapper onClick={handleOptionsClick}>
            <EventOptionsMenu
              onReport={handleReport}
              onBlock={() => onBlockCreator(event.createdBy)}
              onViewProfile={() => onViewCreatorProfile(event.createdBy)}
            />
          </OptionsMenuWrapper>
        </HeaderContainer>
        
        <ContentContainer>
          <ImageContainer>
            {!imageError && event.imageUrl ? (
              <EventImage 
                src={event.imageUrl} 
                alt={event.title}
                onError={handleImageError}
              />
            ) : (
              <GradientFallback categoryColor={categoryColor}>
                <CategoryIconPlaceholder>
                  {CategoryIconComponent && <CategoryIconComponent fontSize="large" />}
                </CategoryIconPlaceholder>
              </GradientFallback>
            )}
          </ImageContainer>
          <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
            <InfoItem>
              <CalendarTodayIcon />
              <Typography variant="body2" color="textSecondary">
                {formatEventDate(event.startDate, event.endDate)}
              </Typography>
            </InfoItem>
            <InfoItem>
              <LocationOnIcon />
              <Typography variant="caption" noWrap>
                {event.venueName || truncate(event.address, 30)} 
                {event.distance ? ` (${event.distance.toFixed(1)} km)` : ''}
              </Typography>
            </InfoItem>
            <InfoItem>
              <AttachMoneyIcon fontSize="small" color="primary" />
              <Typography variant="body2" color="textSecondary">
                {event.price ? `${event.price}` : 'Prix non disponible'}
              </Typography>
            </InfoItem>
            {event.isAutomated && (
              <InfoItem>
                <Tooltip title="This event was added automatically" arrow>
                  <SmartToyIcon fontSize="small" />
                </Tooltip>
              </InfoItem>
            )}
            <Box display="flex" mt={0.5} flexWrap="wrap" gap={0.5}>
              {event.hashtags?.slice(0, 3).map((tag, index) => (
                <Chip
                  key={`${tag}-${index}`}
                  label={truncate(tag, 10)}
                  size="small"
                  onClick={handleHashtagClick(tag)}
                  sx={{ 
                    fontSize: '0.6rem', 
                    height: 20,
                    maxWidth: '100px'
                  }}
                />
              ))}
            </Box>
          </Box>
          <ArrowIconButton size="small" onClick={handleClick} aria-label="View event details">
            <ArrowForwardIosIcon />
          </ArrowIconButton>
        </ContentContainer>
      </StyledListItem>
      
      <ReportDialog
        open={openReportDialog}
        onClose={handleCloseReport}
        eventId={event.id}
        latitude={event.coordinates?.latitude}
        longitude={event.coordinates?.longitude}
      />
    </>
  );
});

EventListItem.displayName = 'EventListItem';

export default EventListItem;